
@media (min-width: 1200px) {
  h1 {
    font-size: 2.5rem;
  }
}

@media (min-width: 1200px) {
  h2 {
    font-size: 2.1875rem;
  }
}

@media (min-width: 1200px) {
  h3 {
    font-size: 1.625rem;
  }
}

@media (min-width: 1200px) {
  h4 {
    font-size: 1.5rem;
  }
}

@media (min-width: 1200px) {
  h5 {
    font-size: 1.375rem;
  }
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container-xl, .container-llg, .container-lg, .container-md, .container-sm, .container {
    max-width: 1170px;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-sm,
  .gx-lg-sm {
    --bs-gutter-x: 0.3125rem;
  }
  .g-lg-sm,
  .gy-lg-sm {
    --bs-gutter-y: 0.3125rem;
  }
  .g-lg-3px,
  .gx-lg-3px {
    --bs-gutter-x: 3px;
  }
  .g-lg-3px,
  .gy-lg-3px {
    --bs-gutter-y: 3px;
  }
  .g-lg-15px,
  .gx-lg-15px {
    --bs-gutter-x: 15px;
  }
  .g-lg-15px,
  .gy-lg-15px {
    --bs-gutter-y: 15px;
  }
  .g-lg-25px,
  .gx-lg-25px {
    --bs-gutter-x: 25px;
  }
  .g-lg-25px,
  .gy-lg-25px {
    --bs-gutter-y: 25px;
  }
  .g-lg-35px,
  .gx-lg-35px {
    --bs-gutter-x: 35px;
  }
  .g-lg-35px,
  .gy-lg-35px {
    --bs-gutter-y: 35px;
  }
  .g-lg-65px,
  .gx-lg-65px {
    --bs-gutter-x: 65px;
  }
  .g-lg-65px,
  .gy-lg-65px {
    --bs-gutter-y: 65px;
  }
  .g-lg-74px,
  .gx-lg-74px {
    --bs-gutter-x: 74px;
  }
  .g-lg-74px,
  .gy-lg-74px {
    --bs-gutter-y: 74px;
  }
  .g-lg-700,
  .gx-lg-700 {
    --bs-gutter-x: 700px;
  }
  .g-lg-700,
  .gy-lg-700 {
    --bs-gutter-y: 700px;
  }
  .g-lg-n15,
  .gx-lg-n15 {
    --bs-gutter-x: -15px;
  }
  .g-lg-n15,
  .gy-lg-n15 {
    --bs-gutter-y: -15px;
  }
  .g-lg-n1,
  .gx-lg-n1 {
    --bs-gutter-x: -10px;
  }
  .g-lg-n1,
  .gy-lg-n1 {
    --bs-gutter-y: -10px;
  }
  .g-lg-n5,
  .gx-lg-n5 {
    --bs-gutter-x: -5px;
  }
  .g-lg-n5,
  .gy-lg-n5 {
    --bs-gutter-y: -5px;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.625rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.625rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 1.25rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 1.25rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1.875rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1.875rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 2.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 2.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3.125rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3.125rem;
  }
  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 3.75rem;
  }
  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 3.75rem;
  }
  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 4.375rem;
  }
  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 4.375rem;
  }
  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 5rem;
  }
  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 5rem;
  }
  .g-lg-9,
  .gx-lg-9 {
    --bs-gutter-x: 5.625rem;
  }
  .g-lg-9,
  .gy-lg-9 {
    --bs-gutter-y: 5.625rem;
  }
  .g-lg-10,
  .gx-lg-10 {
    --bs-gutter-x: 6.25rem;
  }
  .g-lg-10,
  .gy-lg-10 {
    --bs-gutter-y: 6.25rem;
  }
  .g-lg-gutter,
  .gx-lg-gutter {
    --bs-gutter-x: 2.133rem;
  }
  .g-lg-gutter,
  .gy-lg-gutter {
    --bs-gutter-y: 2.133rem;
  }
  .g-lg-out-container,
  .gx-lg-out-container {
    --bs-gutter-x: calc(50% - 50vw);
  }
  .g-lg-out-container,
  .gy-lg-out-container {
    --bs-gutter-y: calc(50% - 50vw);
  }
}

@media (min-width: 1200px) {
  .col-llg {
    flex: 1 0 0%;
  }
  .row-cols-llg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-llg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-llg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-llg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-llg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-llg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-llg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-llg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-llg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-llg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-llg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-llg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-llg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-llg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-llg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-llg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-llg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-llg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-llg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-llg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-llg-0 {
    margin-left: 0;
  }
  .offset-llg-1 {
    margin-left: 8.33333333%;
  }
  .offset-llg-2 {
    margin-left: 16.66666667%;
  }
  .offset-llg-3 {
    margin-left: 25%;
  }
  .offset-llg-4 {
    margin-left: 33.33333333%;
  }
  .offset-llg-5 {
    margin-left: 41.66666667%;
  }
  .offset-llg-6 {
    margin-left: 50%;
  }
  .offset-llg-7 {
    margin-left: 58.33333333%;
  }
  .offset-llg-8 {
    margin-left: 66.66666667%;
  }
  .offset-llg-9 {
    margin-left: 75%;
  }
  .offset-llg-10 {
    margin-left: 83.33333333%;
  }
  .offset-llg-11 {
    margin-left: 91.66666667%;
  }
  .g-llg-sm,
  .gx-llg-sm {
    --bs-gutter-x: 0.3125rem;
  }
  .g-llg-sm,
  .gy-llg-sm {
    --bs-gutter-y: 0.3125rem;
  }
  .g-llg-3px,
  .gx-llg-3px {
    --bs-gutter-x: 3px;
  }
  .g-llg-3px,
  .gy-llg-3px {
    --bs-gutter-y: 3px;
  }
  .g-llg-15px,
  .gx-llg-15px {
    --bs-gutter-x: 15px;
  }
  .g-llg-15px,
  .gy-llg-15px {
    --bs-gutter-y: 15px;
  }
  .g-llg-25px,
  .gx-llg-25px {
    --bs-gutter-x: 25px;
  }
  .g-llg-25px,
  .gy-llg-25px {
    --bs-gutter-y: 25px;
  }
  .g-llg-35px,
  .gx-llg-35px {
    --bs-gutter-x: 35px;
  }
  .g-llg-35px,
  .gy-llg-35px {
    --bs-gutter-y: 35px;
  }
  .g-llg-65px,
  .gx-llg-65px {
    --bs-gutter-x: 65px;
  }
  .g-llg-65px,
  .gy-llg-65px {
    --bs-gutter-y: 65px;
  }
  .g-llg-74px,
  .gx-llg-74px {
    --bs-gutter-x: 74px;
  }
  .g-llg-74px,
  .gy-llg-74px {
    --bs-gutter-y: 74px;
  }
  .g-llg-700,
  .gx-llg-700 {
    --bs-gutter-x: 700px;
  }
  .g-llg-700,
  .gy-llg-700 {
    --bs-gutter-y: 700px;
  }
  .g-llg-n15,
  .gx-llg-n15 {
    --bs-gutter-x: -15px;
  }
  .g-llg-n15,
  .gy-llg-n15 {
    --bs-gutter-y: -15px;
  }
  .g-llg-n1,
  .gx-llg-n1 {
    --bs-gutter-x: -10px;
  }
  .g-llg-n1,
  .gy-llg-n1 {
    --bs-gutter-y: -10px;
  }
  .g-llg-n5,
  .gx-llg-n5 {
    --bs-gutter-x: -5px;
  }
  .g-llg-n5,
  .gy-llg-n5 {
    --bs-gutter-y: -5px;
  }
  .g-llg-0,
  .gx-llg-0 {
    --bs-gutter-x: 0;
  }
  .g-llg-0,
  .gy-llg-0 {
    --bs-gutter-y: 0;
  }
  .g-llg-1,
  .gx-llg-1 {
    --bs-gutter-x: 0.625rem;
  }
  .g-llg-1,
  .gy-llg-1 {
    --bs-gutter-y: 0.625rem;
  }
  .g-llg-2,
  .gx-llg-2 {
    --bs-gutter-x: 1.25rem;
  }
  .g-llg-2,
  .gy-llg-2 {
    --bs-gutter-y: 1.25rem;
  }
  .g-llg-3,
  .gx-llg-3 {
    --bs-gutter-x: 1.875rem;
  }
  .g-llg-3,
  .gy-llg-3 {
    --bs-gutter-y: 1.875rem;
  }
  .g-llg-4,
  .gx-llg-4 {
    --bs-gutter-x: 2.5rem;
  }
  .g-llg-4,
  .gy-llg-4 {
    --bs-gutter-y: 2.5rem;
  }
  .g-llg-5,
  .gx-llg-5 {
    --bs-gutter-x: 3.125rem;
  }
  .g-llg-5,
  .gy-llg-5 {
    --bs-gutter-y: 3.125rem;
  }
  .g-llg-6,
  .gx-llg-6 {
    --bs-gutter-x: 3.75rem;
  }
  .g-llg-6,
  .gy-llg-6 {
    --bs-gutter-y: 3.75rem;
  }
  .g-llg-7,
  .gx-llg-7 {
    --bs-gutter-x: 4.375rem;
  }
  .g-llg-7,
  .gy-llg-7 {
    --bs-gutter-y: 4.375rem;
  }
  .g-llg-8,
  .gx-llg-8 {
    --bs-gutter-x: 5rem;
  }
  .g-llg-8,
  .gy-llg-8 {
    --bs-gutter-y: 5rem;
  }
  .g-llg-9,
  .gx-llg-9 {
    --bs-gutter-x: 5.625rem;
  }
  .g-llg-9,
  .gy-llg-9 {
    --bs-gutter-y: 5.625rem;
  }
  .g-llg-10,
  .gx-llg-10 {
    --bs-gutter-x: 6.25rem;
  }
  .g-llg-10,
  .gy-llg-10 {
    --bs-gutter-y: 6.25rem;
  }
  .g-llg-gutter,
  .gx-llg-gutter {
    --bs-gutter-x: 2.133rem;
  }
  .g-llg-gutter,
  .gy-llg-gutter {
    --bs-gutter-y: 2.133rem;
  }
  .g-llg-out-container,
  .gx-llg-out-container {
    --bs-gutter-x: calc(50% - 50vw);
  }
  .g-llg-out-container,
  .gy-llg-out-container {
    --bs-gutter-y: calc(50% - 50vw);
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-sm,
  .gx-xl-sm {
    --bs-gutter-x: 0.3125rem;
  }
  .g-xl-sm,
  .gy-xl-sm {
    --bs-gutter-y: 0.3125rem;
  }
  .g-xl-3px,
  .gx-xl-3px {
    --bs-gutter-x: 3px;
  }
  .g-xl-3px,
  .gy-xl-3px {
    --bs-gutter-y: 3px;
  }
  .g-xl-15px,
  .gx-xl-15px {
    --bs-gutter-x: 15px;
  }
  .g-xl-15px,
  .gy-xl-15px {
    --bs-gutter-y: 15px;
  }
  .g-xl-25px,
  .gx-xl-25px {
    --bs-gutter-x: 25px;
  }
  .g-xl-25px,
  .gy-xl-25px {
    --bs-gutter-y: 25px;
  }
  .g-xl-35px,
  .gx-xl-35px {
    --bs-gutter-x: 35px;
  }
  .g-xl-35px,
  .gy-xl-35px {
    --bs-gutter-y: 35px;
  }
  .g-xl-65px,
  .gx-xl-65px {
    --bs-gutter-x: 65px;
  }
  .g-xl-65px,
  .gy-xl-65px {
    --bs-gutter-y: 65px;
  }
  .g-xl-74px,
  .gx-xl-74px {
    --bs-gutter-x: 74px;
  }
  .g-xl-74px,
  .gy-xl-74px {
    --bs-gutter-y: 74px;
  }
  .g-xl-700,
  .gx-xl-700 {
    --bs-gutter-x: 700px;
  }
  .g-xl-700,
  .gy-xl-700 {
    --bs-gutter-y: 700px;
  }
  .g-xl-n15,
  .gx-xl-n15 {
    --bs-gutter-x: -15px;
  }
  .g-xl-n15,
  .gy-xl-n15 {
    --bs-gutter-y: -15px;
  }
  .g-xl-n1,
  .gx-xl-n1 {
    --bs-gutter-x: -10px;
  }
  .g-xl-n1,
  .gy-xl-n1 {
    --bs-gutter-y: -10px;
  }
  .g-xl-n5,
  .gx-xl-n5 {
    --bs-gutter-x: -5px;
  }
  .g-xl-n5,
  .gy-xl-n5 {
    --bs-gutter-y: -5px;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.625rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.625rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 1.25rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 1.25rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1.875rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1.875rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 2.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 2.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3.125rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3.125rem;
  }
  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 3.75rem;
  }
  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 3.75rem;
  }
  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 4.375rem;
  }
  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 4.375rem;
  }
  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 5rem;
  }
  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 5rem;
  }
  .g-xl-9,
  .gx-xl-9 {
    --bs-gutter-x: 5.625rem;
  }
  .g-xl-9,
  .gy-xl-9 {
    --bs-gutter-y: 5.625rem;
  }
  .g-xl-10,
  .gx-xl-10 {
    --bs-gutter-x: 6.25rem;
  }
  .g-xl-10,
  .gy-xl-10 {
    --bs-gutter-y: 6.25rem;
  }
  .g-xl-gutter,
  .gx-xl-gutter {
    --bs-gutter-x: 2.133rem;
  }
  .g-xl-gutter,
  .gy-xl-gutter {
    --bs-gutter-y: 2.133rem;
  }
  .g-xl-out-container,
  .gx-xl-out-container {
    --bs-gutter-x: calc(50% - 50vw);
  }
  .g-xl-out-container,
  .gy-xl-out-container {
    --bs-gutter-y: calc(50% - 50vw);
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-sm,
  .gx-xxl-sm {
    --bs-gutter-x: 0.3125rem;
  }
  .g-xxl-sm,
  .gy-xxl-sm {
    --bs-gutter-y: 0.3125rem;
  }
  .g-xxl-3px,
  .gx-xxl-3px {
    --bs-gutter-x: 3px;
  }
  .g-xxl-3px,
  .gy-xxl-3px {
    --bs-gutter-y: 3px;
  }
  .g-xxl-15px,
  .gx-xxl-15px {
    --bs-gutter-x: 15px;
  }
  .g-xxl-15px,
  .gy-xxl-15px {
    --bs-gutter-y: 15px;
  }
  .g-xxl-25px,
  .gx-xxl-25px {
    --bs-gutter-x: 25px;
  }
  .g-xxl-25px,
  .gy-xxl-25px {
    --bs-gutter-y: 25px;
  }
  .g-xxl-35px,
  .gx-xxl-35px {
    --bs-gutter-x: 35px;
  }
  .g-xxl-35px,
  .gy-xxl-35px {
    --bs-gutter-y: 35px;
  }
  .g-xxl-65px,
  .gx-xxl-65px {
    --bs-gutter-x: 65px;
  }
  .g-xxl-65px,
  .gy-xxl-65px {
    --bs-gutter-y: 65px;
  }
  .g-xxl-74px,
  .gx-xxl-74px {
    --bs-gutter-x: 74px;
  }
  .g-xxl-74px,
  .gy-xxl-74px {
    --bs-gutter-y: 74px;
  }
  .g-xxl-700,
  .gx-xxl-700 {
    --bs-gutter-x: 700px;
  }
  .g-xxl-700,
  .gy-xxl-700 {
    --bs-gutter-y: 700px;
  }
  .g-xxl-n15,
  .gx-xxl-n15 {
    --bs-gutter-x: -15px;
  }
  .g-xxl-n15,
  .gy-xxl-n15 {
    --bs-gutter-y: -15px;
  }
  .g-xxl-n1,
  .gx-xxl-n1 {
    --bs-gutter-x: -10px;
  }
  .g-xxl-n1,
  .gy-xxl-n1 {
    --bs-gutter-y: -10px;
  }
  .g-xxl-n5,
  .gx-xxl-n5 {
    --bs-gutter-x: -5px;
  }
  .g-xxl-n5,
  .gy-xxl-n5 {
    --bs-gutter-y: -5px;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.625rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.625rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 1.25rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 1.25rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1.875rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1.875rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 2.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 2.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3.125rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3.125rem;
  }
  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 3.75rem;
  }
  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 3.75rem;
  }
  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 4.375rem;
  }
  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 4.375rem;
  }
  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 5rem;
  }
  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 5rem;
  }
  .g-xxl-9,
  .gx-xxl-9 {
    --bs-gutter-x: 5.625rem;
  }
  .g-xxl-9,
  .gy-xxl-9 {
    --bs-gutter-y: 5.625rem;
  }
  .g-xxl-10,
  .gx-xxl-10 {
    --bs-gutter-x: 6.25rem;
  }
  .g-xxl-10,
  .gy-xxl-10 {
    --bs-gutter-y: 6.25rem;
  }
  .g-xxl-gutter,
  .gx-xxl-gutter {
    --bs-gutter-x: 2.133rem;
  }
  .g-xxl-gutter,
  .gy-xxl-gutter {
    --bs-gutter-y: 2.133rem;
  }
  .g-xxl-out-container,
  .gx-xxl-out-container {
    --bs-gutter-x: calc(50% - 50vw);
  }
  .g-xxl-out-container,
  .gy-xxl-out-container {
    --bs-gutter-y: calc(50% - 50vw);
  }
}

@media (min-width: 992px) {
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .m-lg-sm {
    margin: 0.3125rem !important;
  }
  .m-lg-3px {
    margin: 3px !important;
  }
  .m-lg-15px {
    margin: 15px !important;
  }
  .m-lg-25px {
    margin: 25px !important;
  }
  .m-lg-35px {
    margin: 35px !important;
  }
  .m-lg-65px {
    margin: 65px !important;
  }
  .m-lg-74px {
    margin: 74px !important;
  }
  .m-lg-700 {
    margin: 700px !important;
  }
  .m-lg-n15 {
    margin: -15px !important;
  }
  .m-lg-n1 {
    margin: -10px !important;
  }
  .m-lg-n5 {
    margin: -5px !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.625rem !important;
  }
  .m-lg-2 {
    margin: 1.25rem !important;
  }
  .m-lg-3 {
    margin: 1.875rem !important;
  }
  .m-lg-4 {
    margin: 2.5rem !important;
  }
  .m-lg-5 {
    margin: 3.125rem !important;
  }
  .m-lg-6 {
    margin: 3.75rem !important;
  }
  .m-lg-7 {
    margin: 4.375rem !important;
  }
  .m-lg-8 {
    margin: 5rem !important;
  }
  .m-lg-9 {
    margin: 5.625rem !important;
  }
  .m-lg-10 {
    margin: 6.25rem !important;
  }
  .m-lg-gutter {
    margin: 2.133rem !important;
  }
  .m-lg-out-container {
    margin: calc(50% - 50vw) !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-sm {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }
  .mx-lg-3px {
    margin-right: 3px !important;
    margin-left: 3px !important;
  }
  .mx-lg-15px {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }
  .mx-lg-25px {
    margin-right: 25px !important;
    margin-left: 25px !important;
  }
  .mx-lg-35px {
    margin-right: 35px !important;
    margin-left: 35px !important;
  }
  .mx-lg-65px {
    margin-right: 65px !important;
    margin-left: 65px !important;
  }
  .mx-lg-74px {
    margin-right: 74px !important;
    margin-left: 74px !important;
  }
  .mx-lg-700 {
    margin-right: 700px !important;
    margin-left: 700px !important;
  }
  .mx-lg-n15 {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }
  .mx-lg-n1 {
    margin-right: -10px !important;
    margin-left: -10px !important;
  }
  .mx-lg-n5 {
    margin-right: -5px !important;
    margin-left: -5px !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-lg-2 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-lg-3 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-lg-4 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-lg-6 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }
  .mx-lg-7 {
    margin-right: 4.375rem !important;
    margin-left: 4.375rem !important;
  }
  .mx-lg-8 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-lg-9 {
    margin-right: 5.625rem !important;
    margin-left: 5.625rem !important;
  }
  .mx-lg-10 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important;
  }
  .mx-lg-gutter {
    margin-right: 2.133rem !important;
    margin-left: 2.133rem !important;
  }
  .mx-lg-out-container {
    margin-right: calc(50% - 50vw) !important;
    margin-left: calc(50% - 50vw) !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-sm {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  .my-lg-3px {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }
  .my-lg-15px {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .my-lg-25px {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .my-lg-35px {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .my-lg-65px {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }
  .my-lg-74px {
    margin-top: 74px !important;
    margin-bottom: 74px !important;
  }
  .my-lg-700 {
    margin-top: 700px !important;
    margin-bottom: 700px !important;
  }
  .my-lg-n15 {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }
  .my-lg-n1 {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }
  .my-lg-n5 {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-lg-2 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-lg-3 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-lg-4 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-lg-6 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }
  .my-lg-7 {
    margin-top: 4.375rem !important;
    margin-bottom: 4.375rem !important;
  }
  .my-lg-8 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-lg-9 {
    margin-top: 5.625rem !important;
    margin-bottom: 5.625rem !important;
  }
  .my-lg-10 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
  }
  .my-lg-gutter {
    margin-top: 2.133rem !important;
    margin-bottom: 2.133rem !important;
  }
  .my-lg-out-container {
    margin-top: calc(50% - 50vw) !important;
    margin-bottom: calc(50% - 50vw) !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-sm {
    margin-top: 0.3125rem !important;
  }
  .mt-lg-3px {
    margin-top: 3px !important;
  }
  .mt-lg-15px {
    margin-top: 15px !important;
  }
  .mt-lg-25px {
    margin-top: 25px !important;
  }
  .mt-lg-35px {
    margin-top: 35px !important;
  }
  .mt-lg-65px {
    margin-top: 65px !important;
  }
  .mt-lg-74px {
    margin-top: 74px !important;
  }
  .mt-lg-700 {
    margin-top: 700px !important;
  }
  .mt-lg-n15 {
    margin-top: -15px !important;
  }
  .mt-lg-n1 {
    margin-top: -10px !important;
  }
  .mt-lg-n5 {
    margin-top: -5px !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.625rem !important;
  }
  .mt-lg-2 {
    margin-top: 1.25rem !important;
  }
  .mt-lg-3 {
    margin-top: 1.875rem !important;
  }
  .mt-lg-4 {
    margin-top: 2.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3.125rem !important;
  }
  .mt-lg-6 {
    margin-top: 3.75rem !important;
  }
  .mt-lg-7 {
    margin-top: 4.375rem !important;
  }
  .mt-lg-8 {
    margin-top: 5rem !important;
  }
  .mt-lg-9 {
    margin-top: 5.625rem !important;
  }
  .mt-lg-10 {
    margin-top: 6.25rem !important;
  }
  .mt-lg-gutter {
    margin-top: 2.133rem !important;
  }
  .mt-lg-out-container {
    margin-top: calc(50% - 50vw) !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-sm {
    margin-right: 0.3125rem !important;
  }
  .me-lg-3px {
    margin-right: 3px !important;
  }
  .me-lg-15px {
    margin-right: 15px !important;
  }
  .me-lg-25px {
    margin-right: 25px !important;
  }
  .me-lg-35px {
    margin-right: 35px !important;
  }
  .me-lg-65px {
    margin-right: 65px !important;
  }
  .me-lg-74px {
    margin-right: 74px !important;
  }
  .me-lg-700 {
    margin-right: 700px !important;
  }
  .me-lg-n15 {
    margin-right: -15px !important;
  }
  .me-lg-n1 {
    margin-right: -10px !important;
  }
  .me-lg-n5 {
    margin-right: -5px !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.625rem !important;
  }
  .me-lg-2 {
    margin-right: 1.25rem !important;
  }
  .me-lg-3 {
    margin-right: 1.875rem !important;
  }
  .me-lg-4 {
    margin-right: 2.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3.125rem !important;
  }
  .me-lg-6 {
    margin-right: 3.75rem !important;
  }
  .me-lg-7 {
    margin-right: 4.375rem !important;
  }
  .me-lg-8 {
    margin-right: 5rem !important;
  }
  .me-lg-9 {
    margin-right: 5.625rem !important;
  }
  .me-lg-10 {
    margin-right: 6.25rem !important;
  }
  .me-lg-gutter {
    margin-right: 2.133rem !important;
  }
  .me-lg-out-container {
    margin-right: calc(50% - 50vw) !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-sm {
    margin-bottom: 0.3125rem !important;
  }
  .mb-lg-3px {
    margin-bottom: 3px !important;
  }
  .mb-lg-15px {
    margin-bottom: 15px !important;
  }
  .mb-lg-25px {
    margin-bottom: 25px !important;
  }
  .mb-lg-35px {
    margin-bottom: 35px !important;
  }
  .mb-lg-65px {
    margin-bottom: 65px !important;
  }
  .mb-lg-74px {
    margin-bottom: 74px !important;
  }
  .mb-lg-700 {
    margin-bottom: 700px !important;
  }
  .mb-lg-n15 {
    margin-bottom: -15px !important;
  }
  .mb-lg-n1 {
    margin-bottom: -10px !important;
  }
  .mb-lg-n5 {
    margin-bottom: -5px !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.625rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 1.25rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1.875rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 2.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3.125rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 3.75rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 4.375rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 5rem !important;
  }
  .mb-lg-9 {
    margin-bottom: 5.625rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 6.25rem !important;
  }
  .mb-lg-gutter {
    margin-bottom: 2.133rem !important;
  }
  .mb-lg-out-container {
    margin-bottom: calc(50% - 50vw) !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-sm {
    margin-left: 0.3125rem !important;
  }
  .ms-lg-3px {
    margin-left: 3px !important;
  }
  .ms-lg-15px {
    margin-left: 15px !important;
  }
  .ms-lg-25px {
    margin-left: 25px !important;
  }
  .ms-lg-35px {
    margin-left: 35px !important;
  }
  .ms-lg-65px {
    margin-left: 65px !important;
  }
  .ms-lg-74px {
    margin-left: 74px !important;
  }
  .ms-lg-700 {
    margin-left: 700px !important;
  }
  .ms-lg-n15 {
    margin-left: -15px !important;
  }
  .ms-lg-n1 {
    margin-left: -10px !important;
  }
  .ms-lg-n5 {
    margin-left: -5px !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.625rem !important;
  }
  .ms-lg-2 {
    margin-left: 1.25rem !important;
  }
  .ms-lg-3 {
    margin-left: 1.875rem !important;
  }
  .ms-lg-4 {
    margin-left: 2.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3.125rem !important;
  }
  .ms-lg-6 {
    margin-left: 3.75rem !important;
  }
  .ms-lg-7 {
    margin-left: 4.375rem !important;
  }
  .ms-lg-8 {
    margin-left: 5rem !important;
  }
  .ms-lg-9 {
    margin-left: 5.625rem !important;
  }
  .ms-lg-10 {
    margin-left: 6.25rem !important;
  }
  .ms-lg-gutter {
    margin-left: 2.133rem !important;
  }
  .ms-lg-out-container {
    margin-left: calc(50% - 50vw) !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-sm {
    padding: 0.3125rem !important;
  }
  .p-lg-3px {
    padding: 3px !important;
  }
  .p-lg-15px {
    padding: 15px !important;
  }
  .p-lg-25px {
    padding: 25px !important;
  }
  .p-lg-35px {
    padding: 35px !important;
  }
  .p-lg-65px {
    padding: 65px !important;
  }
  .p-lg-74px {
    padding: 74px !important;
  }
  .p-lg-700 {
    padding: 700px !important;
  }
  .p-lg-n15 {
    padding: -15px !important;
  }
  .p-lg-n1 {
    padding: -10px !important;
  }
  .p-lg-n5 {
    padding: -5px !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.625rem !important;
  }
  .p-lg-2 {
    padding: 1.25rem !important;
  }
  .p-lg-3 {
    padding: 1.875rem !important;
  }
  .p-lg-4 {
    padding: 2.5rem !important;
  }
  .p-lg-5 {
    padding: 3.125rem !important;
  }
  .p-lg-6 {
    padding: 3.75rem !important;
  }
  .p-lg-7 {
    padding: 4.375rem !important;
  }
  .p-lg-8 {
    padding: 5rem !important;
  }
  .p-lg-9 {
    padding: 5.625rem !important;
  }
  .p-lg-10 {
    padding: 6.25rem !important;
  }
  .p-lg-gutter {
    padding: 2.133rem !important;
  }
  .p-lg-out-container {
    padding: calc(50% - 50vw) !important;
  }
  .px-lg-sm {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }
  .px-lg-3px {
    padding-right: 3px !important;
    padding-left: 3px !important;
  }
  .px-lg-15px {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  .px-lg-25px {
    padding-right: 25px !important;
    padding-left: 25px !important;
  }
  .px-lg-35px {
    padding-right: 35px !important;
    padding-left: 35px !important;
  }
  .px-lg-65px {
    padding-right: 65px !important;
    padding-left: 65px !important;
  }
  .px-lg-74px {
    padding-right: 74px !important;
    padding-left: 74px !important;
  }
  .px-lg-700 {
    padding-right: 700px !important;
    padding-left: 700px !important;
  }
  .px-lg-n15 {
    padding-right: -15px !important;
    padding-left: -15px !important;
  }
  .px-lg-n1 {
    padding-right: -10px !important;
    padding-left: -10px !important;
  }
  .px-lg-n5 {
    padding-right: -5px !important;
    padding-left: -5px !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-lg-2 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-lg-3 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-lg-4 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .px-lg-6 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }
  .px-lg-7 {
    padding-right: 4.375rem !important;
    padding-left: 4.375rem !important;
  }
  .px-lg-8 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-lg-9 {
    padding-right: 5.625rem !important;
    padding-left: 5.625rem !important;
  }
  .px-lg-10 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important;
  }
  .px-lg-gutter {
    padding-right: 2.133rem !important;
    padding-left: 2.133rem !important;
  }
  .px-lg-out-container {
    padding-right: calc(50% - 50vw) !important;
    padding-left: calc(50% - 50vw) !important;
  }
  .py-lg-sm {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  .py-lg-3px {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
  .py-lg-15px {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .py-lg-25px {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .py-lg-35px {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .py-lg-65px {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  .py-lg-74px {
    padding-top: 74px !important;
    padding-bottom: 74px !important;
  }
  .py-lg-700 {
    padding-top: 700px !important;
    padding-bottom: 700px !important;
  }
  .py-lg-n15 {
    padding-top: -15px !important;
    padding-bottom: -15px !important;
  }
  .py-lg-n1 {
    padding-top: -10px !important;
    padding-bottom: -10px !important;
  }
  .py-lg-n5 {
    padding-top: -5px !important;
    padding-bottom: -5px !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-lg-2 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-lg-3 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-lg-4 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .py-lg-6 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .py-lg-7 {
    padding-top: 4.375rem !important;
    padding-bottom: 4.375rem !important;
  }
  .py-lg-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-lg-9 {
    padding-top: 5.625rem !important;
    padding-bottom: 5.625rem !important;
  }
  .py-lg-10 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }
  .py-lg-gutter {
    padding-top: 2.133rem !important;
    padding-bottom: 2.133rem !important;
  }
  .py-lg-out-container {
    padding-top: calc(50% - 50vw) !important;
    padding-bottom: calc(50% - 50vw) !important;
  }
  .pt-lg-sm {
    padding-top: 0.3125rem !important;
  }
  .pt-lg-3px {
    padding-top: 3px !important;
  }
  .pt-lg-15px {
    padding-top: 15px !important;
  }
  .pt-lg-25px {
    padding-top: 25px !important;
  }
  .pt-lg-35px {
    padding-top: 35px !important;
  }
  .pt-lg-65px {
    padding-top: 65px !important;
  }
  .pt-lg-74px {
    padding-top: 74px !important;
  }
  .pt-lg-700 {
    padding-top: 700px !important;
  }
  .pt-lg-n15 {
    padding-top: -15px !important;
  }
  .pt-lg-n1 {
    padding-top: -10px !important;
  }
  .pt-lg-n5 {
    padding-top: -5px !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.625rem !important;
  }
  .pt-lg-2 {
    padding-top: 1.25rem !important;
  }
  .pt-lg-3 {
    padding-top: 1.875rem !important;
  }
  .pt-lg-4 {
    padding-top: 2.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3.125rem !important;
  }
  .pt-lg-6 {
    padding-top: 3.75rem !important;
  }
  .pt-lg-7 {
    padding-top: 4.375rem !important;
  }
  .pt-lg-8 {
    padding-top: 5rem !important;
  }
  .pt-lg-9 {
    padding-top: 5.625rem !important;
  }
  .pt-lg-10 {
    padding-top: 6.25rem !important;
  }
  .pt-lg-gutter {
    padding-top: 2.133rem !important;
  }
  .pt-lg-out-container {
    padding-top: calc(50% - 50vw) !important;
  }
  .pe-lg-sm {
    padding-right: 0.3125rem !important;
  }
  .pe-lg-3px {
    padding-right: 3px !important;
  }
  .pe-lg-15px {
    padding-right: 15px !important;
  }
  .pe-lg-25px {
    padding-right: 25px !important;
  }
  .pe-lg-35px {
    padding-right: 35px !important;
  }
  .pe-lg-65px {
    padding-right: 65px !important;
  }
  .pe-lg-74px {
    padding-right: 74px !important;
  }
  .pe-lg-700 {
    padding-right: 700px !important;
  }
  .pe-lg-n15 {
    padding-right: -15px !important;
  }
  .pe-lg-n1 {
    padding-right: -10px !important;
  }
  .pe-lg-n5 {
    padding-right: -5px !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.625rem !important;
  }
  .pe-lg-2 {
    padding-right: 1.25rem !important;
  }
  .pe-lg-3 {
    padding-right: 1.875rem !important;
  }
  .pe-lg-4 {
    padding-right: 2.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3.125rem !important;
  }
  .pe-lg-6 {
    padding-right: 3.75rem !important;
  }
  .pe-lg-7 {
    padding-right: 4.375rem !important;
  }
  .pe-lg-8 {
    padding-right: 5rem !important;
  }
  .pe-lg-9 {
    padding-right: 5.625rem !important;
  }
  .pe-lg-10 {
    padding-right: 6.25rem !important;
  }
  .pe-lg-gutter {
    padding-right: 2.133rem !important;
  }
  .pe-lg-out-container {
    padding-right: calc(50% - 50vw) !important;
  }
  .pb-lg-sm {
    padding-bottom: 0.3125rem !important;
  }
  .pb-lg-3px {
    padding-bottom: 3px !important;
  }
  .pb-lg-15px {
    padding-bottom: 15px !important;
  }
  .pb-lg-25px {
    padding-bottom: 25px !important;
  }
  .pb-lg-35px {
    padding-bottom: 35px !important;
  }
  .pb-lg-65px {
    padding-bottom: 65px !important;
  }
  .pb-lg-74px {
    padding-bottom: 74px !important;
  }
  .pb-lg-700 {
    padding-bottom: 700px !important;
  }
  .pb-lg-n15 {
    padding-bottom: -15px !important;
  }
  .pb-lg-n1 {
    padding-bottom: -10px !important;
  }
  .pb-lg-n5 {
    padding-bottom: -5px !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.625rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 1.25rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1.875rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 2.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3.125rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 3.75rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 4.375rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 5rem !important;
  }
  .pb-lg-9 {
    padding-bottom: 5.625rem !important;
  }
  .pb-lg-10 {
    padding-bottom: 6.25rem !important;
  }
  .pb-lg-gutter {
    padding-bottom: 2.133rem !important;
  }
  .pb-lg-out-container {
    padding-bottom: calc(50% - 50vw) !important;
  }
  .ps-lg-sm {
    padding-left: 0.3125rem !important;
  }
  .ps-lg-3px {
    padding-left: 3px !important;
  }
  .ps-lg-15px {
    padding-left: 15px !important;
  }
  .ps-lg-25px {
    padding-left: 25px !important;
  }
  .ps-lg-35px {
    padding-left: 35px !important;
  }
  .ps-lg-65px {
    padding-left: 65px !important;
  }
  .ps-lg-74px {
    padding-left: 74px !important;
  }
  .ps-lg-700 {
    padding-left: 700px !important;
  }
  .ps-lg-n15 {
    padding-left: -15px !important;
  }
  .ps-lg-n1 {
    padding-left: -10px !important;
  }
  .ps-lg-n5 {
    padding-left: -5px !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.625rem !important;
  }
  .ps-lg-2 {
    padding-left: 1.25rem !important;
  }
  .ps-lg-3 {
    padding-left: 1.875rem !important;
  }
  .ps-lg-4 {
    padding-left: 2.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3.125rem !important;
  }
  .ps-lg-6 {
    padding-left: 3.75rem !important;
  }
  .ps-lg-7 {
    padding-left: 4.375rem !important;
  }
  .ps-lg-8 {
    padding-left: 5rem !important;
  }
  .ps-lg-9 {
    padding-left: 5.625rem !important;
  }
  .ps-lg-10 {
    padding-left: 6.25rem !important;
  }
  .ps-lg-gutter {
    padding-left: 2.133rem !important;
  }
  .ps-lg-out-container {
    padding-left: calc(50% - 50vw) !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-lg-sm {
    gap: 0.3125rem !important;
  }
  .gap-lg-3px {
    gap: 3px !important;
  }
  .gap-lg-15px {
    gap: 15px !important;
  }
  .gap-lg-25px {
    gap: 25px !important;
  }
  .gap-lg-35px {
    gap: 35px !important;
  }
  .gap-lg-65px {
    gap: 65px !important;
  }
  .gap-lg-74px {
    gap: 74px !important;
  }
  .gap-lg-700 {
    gap: 700px !important;
  }
  .gap-lg-n15 {
    gap: -15px !important;
  }
  .gap-lg-n1 {
    gap: -10px !important;
  }
  .gap-lg-n5 {
    gap: -5px !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.625rem !important;
  }
  .gap-lg-2 {
    gap: 1.25rem !important;
  }
  .gap-lg-3 {
    gap: 1.875rem !important;
  }
  .gap-lg-4 {
    gap: 2.5rem !important;
  }
  .gap-lg-5 {
    gap: 3.125rem !important;
  }
  .gap-lg-6 {
    gap: 3.75rem !important;
  }
  .gap-lg-7 {
    gap: 4.375rem !important;
  }
  .gap-lg-8 {
    gap: 5rem !important;
  }
  .gap-lg-9 {
    gap: 5.625rem !important;
  }
  .gap-lg-10 {
    gap: 6.25rem !important;
  }
  .gap-lg-gutter {
    gap: 2.133rem !important;
  }
  .gap-lg-out-container {
    gap: calc(50% - 50vw) !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .vh-lg-30 {
    height: 30vh !important;
  }
  .vh-lg-40 {
    height: 40vh !important;
  }
  .vh-lg-50 {
    height: 50vh !important;
  }
  .vh-lg-60 {
    height: 60vh !important;
  }
  .vh-lg-65 {
    height: 65vh !important;
  }
  .vh-lg-70 {
    height: 70vh !important;
  }
  .vh-lg-80 {
    height: 80vh !important;
  }
  .vh-lg-100 {
    height: 100vh !important;
  }
  .w-lg-10 {
    width: 10% !important;
  }
  .w-lg-20 {
    width: 20% !important;
  }
  .w-lg-30 {
    width: 30% !important;
  }
  .w-lg-40 {
    width: 40% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-60 {
    width: 60% !important;
  }
  .w-lg-70 {
    width: 70% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-80 {
    width: 80% !important;
  }
  .w-lg-85 {
    width: 85% !important;
  }
  .w-lg-90 {
    width: 90% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .w-lg-20px {
    width: 20px !important;
  }
  .w-lg-80px {
    width: 80px !important;
  }
  .w-lg-180 {
    width: 180px !important;
  }
  .w-lg-200px {
    width: 200px !important;
  }
  .w-lg-cal200 {
    width: calc(100% - 200px) !important;
  }
  .h-lg-25 {
    height: 25% !important;
  }
  .h-lg-50 {
    height: 50% !important;
  }
  .h-lg-75 {
    height: 75% !important;
  }
  .h-lg-100 {
    height: 100% !important;
  }
  .h-lg-20px {
    height: 20px !important;
  }
  .h-lg-27px {
    height: 27px !important;
  }
  .h-lg-auto {
    height: auto !important;
  }
  .h-lg-100vh {
    height: 100vh !important;
  }
  .h-lg-300px {
    height: 300px !important;
  }
  .h-lg-400px {
    height: 400px !important;
  }
  .h-lg-600px {
    height: 600px !important;
  }
  .h-lg-750px {
    height: 750px !important;
  }
  .fs-lg-1 {
    font-size: calc(1.375rem + 1.5vw) !important;
  }
  .fs-lg-2 {
    font-size: calc(1.34375rem + 1.125vw) !important;
  }
  .fs-lg-3 {
    font-size: calc(1.2875rem + 0.45vw) !important;
  }
  .fs-lg-4 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }
  .fs-lg-5 {
    font-size: calc(1.2625rem + 0.15vw) !important;
  }
  .fs-lg-6 {
    font-size: 1.125rem !important;
  }
  .fs-lg-7 {
    font-size: 1rem !important;
  }
  .fs-lg-xl {
    font-size: calc(1.5rem + 3vw) !important;
  }
  .fs-lg-lg {
    font-size: calc(1.3125rem + 0.75vw) !important;
  }
  .fs-lg-md {
    font-size: calc(1.35rem + 1.2vw) !important;
  }
  .fs-lg-sm {
    font-size: 0.875rem !important;
  }
  .fs-lg-xs {
    font-size: 0.75rem !important;
  }
  .fs-lg-xxs {
    font-size: 0.625rem !important;
  }
  .fs-lg-15 {
    font-size: 0.9375rem !important;
  }
  .fs-lg-12 {
    font-size: 0.75rem !important;
  }
  .fs-lg-25 {
    font-size: calc(1.28125rem + 0.375vw) !important;
  }
  .fs-lg-30 {
    font-size: calc(1.3125rem + 0.75vw) !important;
  }
  .fs-lg-55 {
    font-size: calc(1.46875rem + 2.625vw) !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .order-lg-unset {
    order: unset !important;
  }
  .cursor-lg-auto {
    cursor: auto !important;
  }
  .cursor-lg-pointer {
    cursor: pointer !important;
  }
  .cursor-lg-grab {
    cursor: grab !important;
  }
  .border-lg {
    border: 1px solid #5a5a5a !important;
  }
  .border-lg-0 {
    border: 0 !important;
  }
  .border-top-lg {
    border-top: 1px solid #5a5a5a !important;
  }
  .border-top-lg-0 {
    border-top: 0 !important;
  }
  .border-start-lg {
    border-left: 1px solid #5a5a5a !important;
  }
  .border-start-lg-0 {
    border-left: 0 !important;
  }
  .border-end-lg {
    border-right: 1px solid #5a5a5a !important;
  }
  .border-end-lg-0 {
    border-right: 0 !important;
  }
  .border-bottom-lg {
    border-bottom: 1px solid #5a5a5a !important;
  }
  .border-bottom-lg-0 {
    border-bottom: 0 !important;
  }
  .shadow-lg {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16) !important;
  }
  .shadow-lg-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-lg-lg {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16) !important;
  }
  .shadow-lg-none {
    box-shadow: none !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
  .text-decoration-lg-none {
    text-decoration: none !important;
  }
  .text-decoration-lg-underline {
    text-decoration: underline !important;
  }
  .text-decoration-lg-line-through {
    text-decoration: line-through !important;
  }
  .text-lg-lowercase {
    text-transform: lowercase !important;
  }
  .text-lg-uppercase {
    text-transform: uppercase !important;
  }
  .text-lg-capitalize {
    text-transform: capitalize !important;
  }
  .position-lg-static {
    position: static !important;
  }
  .position-lg-relative {
    position: relative !important;
  }
  .position-lg-absolute {
    position: absolute !important;
  }
  .position-lg-fixed {
    position: fixed !important;
  }
  .position-lg-sticky {
    position: sticky !important;
  }
  .top-lg-0 {
    top: 0 !important;
  }
  .top-lg-25 {
    top: 25% !important;
  }
  .top-lg-50 {
    top: 50% !important;
  }
  .top-lg-75 {
    top: 75% !important;
  }
  .top-lg-100 {
    top: 100% !important;
  }
  .top-lg-10 {
    top: 10px !important;
  }
  .top-lg-20 {
    top: 20px !important;
  }
  .top-lg-30 {
    top: 30px !important;
  }
  .top-lg-n10 {
    top: -10px !important;
  }
  .top-lg-n20 {
    top: -20px !important;
  }
  .top-lg-n8 {
    top: -8px !important;
  }
  .top-lg-7 {
    top: 7px !important;
  }
  .top-lg-13 {
    top: 13px !important;
  }
  .top-lg-15 {
    top: 15px !important;
  }
  .top-lg-n3 {
    top: -3px !important;
  }
  .top-lg-5 {
    top: 5px !important;
  }
  .top-lg-2 {
    top: 2px !important;
  }
  .bottom-lg-0 {
    bottom: 0 !important;
  }
  .bottom-lg-25 {
    bottom: 25% !important;
  }
  .bottom-lg-50 {
    bottom: 50% !important;
  }
  .bottom-lg-75 {
    bottom: 75% !important;
  }
  .bottom-lg-100 {
    bottom: 100% !important;
  }
  .bottom-lg-10 {
    bottom: 10px !important;
  }
  .bottom-lg-20 {
    bottom: 20px !important;
  }
  .bottom-lg-30 {
    bottom: 30px !important;
  }
  .bottom-lg-n10 {
    bottom: -10px !important;
  }
  .bottom-lg-n20 {
    bottom: -20px !important;
  }
  .bottom-lg-n8 {
    bottom: -8px !important;
  }
  .bottom-lg-7 {
    bottom: 7px !important;
  }
  .bottom-lg-13 {
    bottom: 13px !important;
  }
  .bottom-lg-15 {
    bottom: 15px !important;
  }
  .bottom-lg-n3 {
    bottom: -3px !important;
  }
  .bottom-lg-5 {
    bottom: 5px !important;
  }
  .bottom-lg-2 {
    bottom: 2px !important;
  }
  .end-lg-0 {
    right: 0 !important;
  }
  .end-lg-25 {
    right: 25% !important;
  }
  .end-lg-50 {
    right: 50% !important;
  }
  .end-lg-75 {
    right: 75% !important;
  }
  .end-lg-100 {
    right: 100% !important;
  }
  .end-lg-10 {
    right: 10px !important;
  }
  .end-lg-20 {
    right: 20px !important;
  }
  .end-lg-30 {
    right: 30px !important;
  }
  .end-lg-n10 {
    right: -10px !important;
  }
  .end-lg-n20 {
    right: -20px !important;
  }
  .end-lg-n8 {
    right: -8px !important;
  }
  .end-lg-7 {
    right: 7px !important;
  }
  .end-lg-13 {
    right: 13px !important;
  }
  .end-lg-15 {
    right: 15px !important;
  }
  .end-lg-n3 {
    right: -3px !important;
  }
  .end-lg-5 {
    right: 5px !important;
  }
  .end-lg-2 {
    right: 2px !important;
  }
  .translate-middle-lg {
    transform: translate(-50%, -50%) !important;
  }
  .translate-middle-lg-x {
    transform: translateX(-50%) !important;
  }
  .translate-middle-lg-y {
    transform: translateY(-50%) !important;
  }
}

@media (min-width: 1200px) {
  .d-llg-inline {
    display: inline !important;
  }
  .d-llg-inline-block {
    display: inline-block !important;
  }
  .d-llg-block {
    display: block !important;
  }
  .d-llg-grid {
    display: grid !important;
  }
  .d-llg-table {
    display: table !important;
  }
  .d-llg-table-row {
    display: table-row !important;
  }
  .d-llg-table-cell {
    display: table-cell !important;
  }
  .d-llg-flex {
    display: flex !important;
  }
  .d-llg-inline-flex {
    display: inline-flex !important;
  }
  .d-llg-none {
    display: none !important;
  }
  .m-llg-sm {
    margin: 0.3125rem !important;
  }
  .m-llg-3px {
    margin: 3px !important;
  }
  .m-llg-15px {
    margin: 15px !important;
  }
  .m-llg-25px {
    margin: 25px !important;
  }
  .m-llg-35px {
    margin: 35px !important;
  }
  .m-llg-65px {
    margin: 65px !important;
  }
  .m-llg-74px {
    margin: 74px !important;
  }
  .m-llg-700 {
    margin: 700px !important;
  }
  .m-llg-n15 {
    margin: -15px !important;
  }
  .m-llg-n1 {
    margin: -10px !important;
  }
  .m-llg-n5 {
    margin: -5px !important;
  }
  .m-llg-0 {
    margin: 0 !important;
  }
  .m-llg-1 {
    margin: 0.625rem !important;
  }
  .m-llg-2 {
    margin: 1.25rem !important;
  }
  .m-llg-3 {
    margin: 1.875rem !important;
  }
  .m-llg-4 {
    margin: 2.5rem !important;
  }
  .m-llg-5 {
    margin: 3.125rem !important;
  }
  .m-llg-6 {
    margin: 3.75rem !important;
  }
  .m-llg-7 {
    margin: 4.375rem !important;
  }
  .m-llg-8 {
    margin: 5rem !important;
  }
  .m-llg-9 {
    margin: 5.625rem !important;
  }
  .m-llg-10 {
    margin: 6.25rem !important;
  }
  .m-llg-gutter {
    margin: 2.133rem !important;
  }
  .m-llg-out-container {
    margin: calc(50% - 50vw) !important;
  }
  .m-llg-auto {
    margin: auto !important;
  }
  .mx-llg-sm {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }
  .mx-llg-3px {
    margin-right: 3px !important;
    margin-left: 3px !important;
  }
  .mx-llg-15px {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }
  .mx-llg-25px {
    margin-right: 25px !important;
    margin-left: 25px !important;
  }
  .mx-llg-35px {
    margin-right: 35px !important;
    margin-left: 35px !important;
  }
  .mx-llg-65px {
    margin-right: 65px !important;
    margin-left: 65px !important;
  }
  .mx-llg-74px {
    margin-right: 74px !important;
    margin-left: 74px !important;
  }
  .mx-llg-700 {
    margin-right: 700px !important;
    margin-left: 700px !important;
  }
  .mx-llg-n15 {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }
  .mx-llg-n1 {
    margin-right: -10px !important;
    margin-left: -10px !important;
  }
  .mx-llg-n5 {
    margin-right: -5px !important;
    margin-left: -5px !important;
  }
  .mx-llg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-llg-1 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-llg-2 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-llg-3 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-llg-4 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-llg-5 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-llg-6 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }
  .mx-llg-7 {
    margin-right: 4.375rem !important;
    margin-left: 4.375rem !important;
  }
  .mx-llg-8 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-llg-9 {
    margin-right: 5.625rem !important;
    margin-left: 5.625rem !important;
  }
  .mx-llg-10 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important;
  }
  .mx-llg-gutter {
    margin-right: 2.133rem !important;
    margin-left: 2.133rem !important;
  }
  .mx-llg-out-container {
    margin-right: calc(50% - 50vw) !important;
    margin-left: calc(50% - 50vw) !important;
  }
  .mx-llg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-llg-sm {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  .my-llg-3px {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }
  .my-llg-15px {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .my-llg-25px {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .my-llg-35px {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .my-llg-65px {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }
  .my-llg-74px {
    margin-top: 74px !important;
    margin-bottom: 74px !important;
  }
  .my-llg-700 {
    margin-top: 700px !important;
    margin-bottom: 700px !important;
  }
  .my-llg-n15 {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }
  .my-llg-n1 {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }
  .my-llg-n5 {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }
  .my-llg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-llg-1 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-llg-2 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-llg-3 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-llg-4 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-llg-5 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-llg-6 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }
  .my-llg-7 {
    margin-top: 4.375rem !important;
    margin-bottom: 4.375rem !important;
  }
  .my-llg-8 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-llg-9 {
    margin-top: 5.625rem !important;
    margin-bottom: 5.625rem !important;
  }
  .my-llg-10 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
  }
  .my-llg-gutter {
    margin-top: 2.133rem !important;
    margin-bottom: 2.133rem !important;
  }
  .my-llg-out-container {
    margin-top: calc(50% - 50vw) !important;
    margin-bottom: calc(50% - 50vw) !important;
  }
  .my-llg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-llg-sm {
    margin-top: 0.3125rem !important;
  }
  .mt-llg-3px {
    margin-top: 3px !important;
  }
  .mt-llg-15px {
    margin-top: 15px !important;
  }
  .mt-llg-25px {
    margin-top: 25px !important;
  }
  .mt-llg-35px {
    margin-top: 35px !important;
  }
  .mt-llg-65px {
    margin-top: 65px !important;
  }
  .mt-llg-74px {
    margin-top: 74px !important;
  }
  .mt-llg-700 {
    margin-top: 700px !important;
  }
  .mt-llg-n15 {
    margin-top: -15px !important;
  }
  .mt-llg-n1 {
    margin-top: -10px !important;
  }
  .mt-llg-n5 {
    margin-top: -5px !important;
  }
  .mt-llg-0 {
    margin-top: 0 !important;
  }
  .mt-llg-1 {
    margin-top: 0.625rem !important;
  }
  .mt-llg-2 {
    margin-top: 1.25rem !important;
  }
  .mt-llg-3 {
    margin-top: 1.875rem !important;
  }
  .mt-llg-4 {
    margin-top: 2.5rem !important;
  }
  .mt-llg-5 {
    margin-top: 3.125rem !important;
  }
  .mt-llg-6 {
    margin-top: 3.75rem !important;
  }
  .mt-llg-7 {
    margin-top: 4.375rem !important;
  }
  .mt-llg-8 {
    margin-top: 5rem !important;
  }
  .mt-llg-9 {
    margin-top: 5.625rem !important;
  }
  .mt-llg-10 {
    margin-top: 6.25rem !important;
  }
  .mt-llg-gutter {
    margin-top: 2.133rem !important;
  }
  .mt-llg-out-container {
    margin-top: calc(50% - 50vw) !important;
  }
  .mt-llg-auto {
    margin-top: auto !important;
  }
  .me-llg-sm {
    margin-right: 0.3125rem !important;
  }
  .me-llg-3px {
    margin-right: 3px !important;
  }
  .me-llg-15px {
    margin-right: 15px !important;
  }
  .me-llg-25px {
    margin-right: 25px !important;
  }
  .me-llg-35px {
    margin-right: 35px !important;
  }
  .me-llg-65px {
    margin-right: 65px !important;
  }
  .me-llg-74px {
    margin-right: 74px !important;
  }
  .me-llg-700 {
    margin-right: 700px !important;
  }
  .me-llg-n15 {
    margin-right: -15px !important;
  }
  .me-llg-n1 {
    margin-right: -10px !important;
  }
  .me-llg-n5 {
    margin-right: -5px !important;
  }
  .me-llg-0 {
    margin-right: 0 !important;
  }
  .me-llg-1 {
    margin-right: 0.625rem !important;
  }
  .me-llg-2 {
    margin-right: 1.25rem !important;
  }
  .me-llg-3 {
    margin-right: 1.875rem !important;
  }
  .me-llg-4 {
    margin-right: 2.5rem !important;
  }
  .me-llg-5 {
    margin-right: 3.125rem !important;
  }
  .me-llg-6 {
    margin-right: 3.75rem !important;
  }
  .me-llg-7 {
    margin-right: 4.375rem !important;
  }
  .me-llg-8 {
    margin-right: 5rem !important;
  }
  .me-llg-9 {
    margin-right: 5.625rem !important;
  }
  .me-llg-10 {
    margin-right: 6.25rem !important;
  }
  .me-llg-gutter {
    margin-right: 2.133rem !important;
  }
  .me-llg-out-container {
    margin-right: calc(50% - 50vw) !important;
  }
  .me-llg-auto {
    margin-right: auto !important;
  }
  .mb-llg-sm {
    margin-bottom: 0.3125rem !important;
  }
  .mb-llg-3px {
    margin-bottom: 3px !important;
  }
  .mb-llg-15px {
    margin-bottom: 15px !important;
  }
  .mb-llg-25px {
    margin-bottom: 25px !important;
  }
  .mb-llg-35px {
    margin-bottom: 35px !important;
  }
  .mb-llg-65px {
    margin-bottom: 65px !important;
  }
  .mb-llg-74px {
    margin-bottom: 74px !important;
  }
  .mb-llg-700 {
    margin-bottom: 700px !important;
  }
  .mb-llg-n15 {
    margin-bottom: -15px !important;
  }
  .mb-llg-n1 {
    margin-bottom: -10px !important;
  }
  .mb-llg-n5 {
    margin-bottom: -5px !important;
  }
  .mb-llg-0 {
    margin-bottom: 0 !important;
  }
  .mb-llg-1 {
    margin-bottom: 0.625rem !important;
  }
  .mb-llg-2 {
    margin-bottom: 1.25rem !important;
  }
  .mb-llg-3 {
    margin-bottom: 1.875rem !important;
  }
  .mb-llg-4 {
    margin-bottom: 2.5rem !important;
  }
  .mb-llg-5 {
    margin-bottom: 3.125rem !important;
  }
  .mb-llg-6 {
    margin-bottom: 3.75rem !important;
  }
  .mb-llg-7 {
    margin-bottom: 4.375rem !important;
  }
  .mb-llg-8 {
    margin-bottom: 5rem !important;
  }
  .mb-llg-9 {
    margin-bottom: 5.625rem !important;
  }
  .mb-llg-10 {
    margin-bottom: 6.25rem !important;
  }
  .mb-llg-gutter {
    margin-bottom: 2.133rem !important;
  }
  .mb-llg-out-container {
    margin-bottom: calc(50% - 50vw) !important;
  }
  .mb-llg-auto {
    margin-bottom: auto !important;
  }
  .ms-llg-sm {
    margin-left: 0.3125rem !important;
  }
  .ms-llg-3px {
    margin-left: 3px !important;
  }
  .ms-llg-15px {
    margin-left: 15px !important;
  }
  .ms-llg-25px {
    margin-left: 25px !important;
  }
  .ms-llg-35px {
    margin-left: 35px !important;
  }
  .ms-llg-65px {
    margin-left: 65px !important;
  }
  .ms-llg-74px {
    margin-left: 74px !important;
  }
  .ms-llg-700 {
    margin-left: 700px !important;
  }
  .ms-llg-n15 {
    margin-left: -15px !important;
  }
  .ms-llg-n1 {
    margin-left: -10px !important;
  }
  .ms-llg-n5 {
    margin-left: -5px !important;
  }
  .ms-llg-0 {
    margin-left: 0 !important;
  }
  .ms-llg-1 {
    margin-left: 0.625rem !important;
  }
  .ms-llg-2 {
    margin-left: 1.25rem !important;
  }
  .ms-llg-3 {
    margin-left: 1.875rem !important;
  }
  .ms-llg-4 {
    margin-left: 2.5rem !important;
  }
  .ms-llg-5 {
    margin-left: 3.125rem !important;
  }
  .ms-llg-6 {
    margin-left: 3.75rem !important;
  }
  .ms-llg-7 {
    margin-left: 4.375rem !important;
  }
  .ms-llg-8 {
    margin-left: 5rem !important;
  }
  .ms-llg-9 {
    margin-left: 5.625rem !important;
  }
  .ms-llg-10 {
    margin-left: 6.25rem !important;
  }
  .ms-llg-gutter {
    margin-left: 2.133rem !important;
  }
  .ms-llg-out-container {
    margin-left: calc(50% - 50vw) !important;
  }
  .ms-llg-auto {
    margin-left: auto !important;
  }
  .p-llg-sm {
    padding: 0.3125rem !important;
  }
  .p-llg-3px {
    padding: 3px !important;
  }
  .p-llg-15px {
    padding: 15px !important;
  }
  .p-llg-25px {
    padding: 25px !important;
  }
  .p-llg-35px {
    padding: 35px !important;
  }
  .p-llg-65px {
    padding: 65px !important;
  }
  .p-llg-74px {
    padding: 74px !important;
  }
  .p-llg-700 {
    padding: 700px !important;
  }
  .p-llg-n15 {
    padding: -15px !important;
  }
  .p-llg-n1 {
    padding: -10px !important;
  }
  .p-llg-n5 {
    padding: -5px !important;
  }
  .p-llg-0 {
    padding: 0 !important;
  }
  .p-llg-1 {
    padding: 0.625rem !important;
  }
  .p-llg-2 {
    padding: 1.25rem !important;
  }
  .p-llg-3 {
    padding: 1.875rem !important;
  }
  .p-llg-4 {
    padding: 2.5rem !important;
  }
  .p-llg-5 {
    padding: 3.125rem !important;
  }
  .p-llg-6 {
    padding: 3.75rem !important;
  }
  .p-llg-7 {
    padding: 4.375rem !important;
  }
  .p-llg-8 {
    padding: 5rem !important;
  }
  .p-llg-9 {
    padding: 5.625rem !important;
  }
  .p-llg-10 {
    padding: 6.25rem !important;
  }
  .p-llg-gutter {
    padding: 2.133rem !important;
  }
  .p-llg-out-container {
    padding: calc(50% - 50vw) !important;
  }
  .px-llg-sm {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }
  .px-llg-3px {
    padding-right: 3px !important;
    padding-left: 3px !important;
  }
  .px-llg-15px {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  .px-llg-25px {
    padding-right: 25px !important;
    padding-left: 25px !important;
  }
  .px-llg-35px {
    padding-right: 35px !important;
    padding-left: 35px !important;
  }
  .px-llg-65px {
    padding-right: 65px !important;
    padding-left: 65px !important;
  }
  .px-llg-74px {
    padding-right: 74px !important;
    padding-left: 74px !important;
  }
  .px-llg-700 {
    padding-right: 700px !important;
    padding-left: 700px !important;
  }
  .px-llg-n15 {
    padding-right: -15px !important;
    padding-left: -15px !important;
  }
  .px-llg-n1 {
    padding-right: -10px !important;
    padding-left: -10px !important;
  }
  .px-llg-n5 {
    padding-right: -5px !important;
    padding-left: -5px !important;
  }
  .px-llg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-llg-1 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-llg-2 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-llg-3 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-llg-4 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-llg-5 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .px-llg-6 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }
  .px-llg-7 {
    padding-right: 4.375rem !important;
    padding-left: 4.375rem !important;
  }
  .px-llg-8 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-llg-9 {
    padding-right: 5.625rem !important;
    padding-left: 5.625rem !important;
  }
  .px-llg-10 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important;
  }
  .px-llg-gutter {
    padding-right: 2.133rem !important;
    padding-left: 2.133rem !important;
  }
  .px-llg-out-container {
    padding-right: calc(50% - 50vw) !important;
    padding-left: calc(50% - 50vw) !important;
  }
  .py-llg-sm {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  .py-llg-3px {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
  .py-llg-15px {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .py-llg-25px {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .py-llg-35px {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .py-llg-65px {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  .py-llg-74px {
    padding-top: 74px !important;
    padding-bottom: 74px !important;
  }
  .py-llg-700 {
    padding-top: 700px !important;
    padding-bottom: 700px !important;
  }
  .py-llg-n15 {
    padding-top: -15px !important;
    padding-bottom: -15px !important;
  }
  .py-llg-n1 {
    padding-top: -10px !important;
    padding-bottom: -10px !important;
  }
  .py-llg-n5 {
    padding-top: -5px !important;
    padding-bottom: -5px !important;
  }
  .py-llg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-llg-1 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-llg-2 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-llg-3 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-llg-4 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-llg-5 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .py-llg-6 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .py-llg-7 {
    padding-top: 4.375rem !important;
    padding-bottom: 4.375rem !important;
  }
  .py-llg-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-llg-9 {
    padding-top: 5.625rem !important;
    padding-bottom: 5.625rem !important;
  }
  .py-llg-10 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }
  .py-llg-gutter {
    padding-top: 2.133rem !important;
    padding-bottom: 2.133rem !important;
  }
  .py-llg-out-container {
    padding-top: calc(50% - 50vw) !important;
    padding-bottom: calc(50% - 50vw) !important;
  }
  .pt-llg-sm {
    padding-top: 0.3125rem !important;
  }
  .pt-llg-3px {
    padding-top: 3px !important;
  }
  .pt-llg-15px {
    padding-top: 15px !important;
  }
  .pt-llg-25px {
    padding-top: 25px !important;
  }
  .pt-llg-35px {
    padding-top: 35px !important;
  }
  .pt-llg-65px {
    padding-top: 65px !important;
  }
  .pt-llg-74px {
    padding-top: 74px !important;
  }
  .pt-llg-700 {
    padding-top: 700px !important;
  }
  .pt-llg-n15 {
    padding-top: -15px !important;
  }
  .pt-llg-n1 {
    padding-top: -10px !important;
  }
  .pt-llg-n5 {
    padding-top: -5px !important;
  }
  .pt-llg-0 {
    padding-top: 0 !important;
  }
  .pt-llg-1 {
    padding-top: 0.625rem !important;
  }
  .pt-llg-2 {
    padding-top: 1.25rem !important;
  }
  .pt-llg-3 {
    padding-top: 1.875rem !important;
  }
  .pt-llg-4 {
    padding-top: 2.5rem !important;
  }
  .pt-llg-5 {
    padding-top: 3.125rem !important;
  }
  .pt-llg-6 {
    padding-top: 3.75rem !important;
  }
  .pt-llg-7 {
    padding-top: 4.375rem !important;
  }
  .pt-llg-8 {
    padding-top: 5rem !important;
  }
  .pt-llg-9 {
    padding-top: 5.625rem !important;
  }
  .pt-llg-10 {
    padding-top: 6.25rem !important;
  }
  .pt-llg-gutter {
    padding-top: 2.133rem !important;
  }
  .pt-llg-out-container {
    padding-top: calc(50% - 50vw) !important;
  }
  .pe-llg-sm {
    padding-right: 0.3125rem !important;
  }
  .pe-llg-3px {
    padding-right: 3px !important;
  }
  .pe-llg-15px {
    padding-right: 15px !important;
  }
  .pe-llg-25px {
    padding-right: 25px !important;
  }
  .pe-llg-35px {
    padding-right: 35px !important;
  }
  .pe-llg-65px {
    padding-right: 65px !important;
  }
  .pe-llg-74px {
    padding-right: 74px !important;
  }
  .pe-llg-700 {
    padding-right: 700px !important;
  }
  .pe-llg-n15 {
    padding-right: -15px !important;
  }
  .pe-llg-n1 {
    padding-right: -10px !important;
  }
  .pe-llg-n5 {
    padding-right: -5px !important;
  }
  .pe-llg-0 {
    padding-right: 0 !important;
  }
  .pe-llg-1 {
    padding-right: 0.625rem !important;
  }
  .pe-llg-2 {
    padding-right: 1.25rem !important;
  }
  .pe-llg-3 {
    padding-right: 1.875rem !important;
  }
  .pe-llg-4 {
    padding-right: 2.5rem !important;
  }
  .pe-llg-5 {
    padding-right: 3.125rem !important;
  }
  .pe-llg-6 {
    padding-right: 3.75rem !important;
  }
  .pe-llg-7 {
    padding-right: 4.375rem !important;
  }
  .pe-llg-8 {
    padding-right: 5rem !important;
  }
  .pe-llg-9 {
    padding-right: 5.625rem !important;
  }
  .pe-llg-10 {
    padding-right: 6.25rem !important;
  }
  .pe-llg-gutter {
    padding-right: 2.133rem !important;
  }
  .pe-llg-out-container {
    padding-right: calc(50% - 50vw) !important;
  }
  .pb-llg-sm {
    padding-bottom: 0.3125rem !important;
  }
  .pb-llg-3px {
    padding-bottom: 3px !important;
  }
  .pb-llg-15px {
    padding-bottom: 15px !important;
  }
  .pb-llg-25px {
    padding-bottom: 25px !important;
  }
  .pb-llg-35px {
    padding-bottom: 35px !important;
  }
  .pb-llg-65px {
    padding-bottom: 65px !important;
  }
  .pb-llg-74px {
    padding-bottom: 74px !important;
  }
  .pb-llg-700 {
    padding-bottom: 700px !important;
  }
  .pb-llg-n15 {
    padding-bottom: -15px !important;
  }
  .pb-llg-n1 {
    padding-bottom: -10px !important;
  }
  .pb-llg-n5 {
    padding-bottom: -5px !important;
  }
  .pb-llg-0 {
    padding-bottom: 0 !important;
  }
  .pb-llg-1 {
    padding-bottom: 0.625rem !important;
  }
  .pb-llg-2 {
    padding-bottom: 1.25rem !important;
  }
  .pb-llg-3 {
    padding-bottom: 1.875rem !important;
  }
  .pb-llg-4 {
    padding-bottom: 2.5rem !important;
  }
  .pb-llg-5 {
    padding-bottom: 3.125rem !important;
  }
  .pb-llg-6 {
    padding-bottom: 3.75rem !important;
  }
  .pb-llg-7 {
    padding-bottom: 4.375rem !important;
  }
  .pb-llg-8 {
    padding-bottom: 5rem !important;
  }
  .pb-llg-9 {
    padding-bottom: 5.625rem !important;
  }
  .pb-llg-10 {
    padding-bottom: 6.25rem !important;
  }
  .pb-llg-gutter {
    padding-bottom: 2.133rem !important;
  }
  .pb-llg-out-container {
    padding-bottom: calc(50% - 50vw) !important;
  }
  .ps-llg-sm {
    padding-left: 0.3125rem !important;
  }
  .ps-llg-3px {
    padding-left: 3px !important;
  }
  .ps-llg-15px {
    padding-left: 15px !important;
  }
  .ps-llg-25px {
    padding-left: 25px !important;
  }
  .ps-llg-35px {
    padding-left: 35px !important;
  }
  .ps-llg-65px {
    padding-left: 65px !important;
  }
  .ps-llg-74px {
    padding-left: 74px !important;
  }
  .ps-llg-700 {
    padding-left: 700px !important;
  }
  .ps-llg-n15 {
    padding-left: -15px !important;
  }
  .ps-llg-n1 {
    padding-left: -10px !important;
  }
  .ps-llg-n5 {
    padding-left: -5px !important;
  }
  .ps-llg-0 {
    padding-left: 0 !important;
  }
  .ps-llg-1 {
    padding-left: 0.625rem !important;
  }
  .ps-llg-2 {
    padding-left: 1.25rem !important;
  }
  .ps-llg-3 {
    padding-left: 1.875rem !important;
  }
  .ps-llg-4 {
    padding-left: 2.5rem !important;
  }
  .ps-llg-5 {
    padding-left: 3.125rem !important;
  }
  .ps-llg-6 {
    padding-left: 3.75rem !important;
  }
  .ps-llg-7 {
    padding-left: 4.375rem !important;
  }
  .ps-llg-8 {
    padding-left: 5rem !important;
  }
  .ps-llg-9 {
    padding-left: 5.625rem !important;
  }
  .ps-llg-10 {
    padding-left: 6.25rem !important;
  }
  .ps-llg-gutter {
    padding-left: 2.133rem !important;
  }
  .ps-llg-out-container {
    padding-left: calc(50% - 50vw) !important;
  }
  .flex-llg-fill {
    flex: 1 1 auto !important;
  }
  .flex-llg-row {
    flex-direction: row !important;
  }
  .flex-llg-column {
    flex-direction: column !important;
  }
  .flex-llg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-llg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-llg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-llg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-llg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-llg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-llg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-llg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-llg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-llg-sm {
    gap: 0.3125rem !important;
  }
  .gap-llg-3px {
    gap: 3px !important;
  }
  .gap-llg-15px {
    gap: 15px !important;
  }
  .gap-llg-25px {
    gap: 25px !important;
  }
  .gap-llg-35px {
    gap: 35px !important;
  }
  .gap-llg-65px {
    gap: 65px !important;
  }
  .gap-llg-74px {
    gap: 74px !important;
  }
  .gap-llg-700 {
    gap: 700px !important;
  }
  .gap-llg-n15 {
    gap: -15px !important;
  }
  .gap-llg-n1 {
    gap: -10px !important;
  }
  .gap-llg-n5 {
    gap: -5px !important;
  }
  .gap-llg-0 {
    gap: 0 !important;
  }
  .gap-llg-1 {
    gap: 0.625rem !important;
  }
  .gap-llg-2 {
    gap: 1.25rem !important;
  }
  .gap-llg-3 {
    gap: 1.875rem !important;
  }
  .gap-llg-4 {
    gap: 2.5rem !important;
  }
  .gap-llg-5 {
    gap: 3.125rem !important;
  }
  .gap-llg-6 {
    gap: 3.75rem !important;
  }
  .gap-llg-7 {
    gap: 4.375rem !important;
  }
  .gap-llg-8 {
    gap: 5rem !important;
  }
  .gap-llg-9 {
    gap: 5.625rem !important;
  }
  .gap-llg-10 {
    gap: 6.25rem !important;
  }
  .gap-llg-gutter {
    gap: 2.133rem !important;
  }
  .gap-llg-out-container {
    gap: calc(50% - 50vw) !important;
  }
  .justify-content-llg-start {
    justify-content: flex-start !important;
  }
  .justify-content-llg-end {
    justify-content: flex-end !important;
  }
  .justify-content-llg-center {
    justify-content: center !important;
  }
  .justify-content-llg-between {
    justify-content: space-between !important;
  }
  .justify-content-llg-around {
    justify-content: space-around !important;
  }
  .justify-content-llg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-llg-start {
    align-items: flex-start !important;
  }
  .align-items-llg-end {
    align-items: flex-end !important;
  }
  .align-items-llg-center {
    align-items: center !important;
  }
  .align-items-llg-baseline {
    align-items: baseline !important;
  }
  .align-items-llg-stretch {
    align-items: stretch !important;
  }
  .align-content-llg-start {
    align-content: flex-start !important;
  }
  .align-content-llg-end {
    align-content: flex-end !important;
  }
  .align-content-llg-center {
    align-content: center !important;
  }
  .align-content-llg-between {
    align-content: space-between !important;
  }
  .align-content-llg-around {
    align-content: space-around !important;
  }
  .align-content-llg-stretch {
    align-content: stretch !important;
  }
  .align-self-llg-auto {
    align-self: auto !important;
  }
  .align-self-llg-start {
    align-self: flex-start !important;
  }
  .align-self-llg-end {
    align-self: flex-end !important;
  }
  .align-self-llg-center {
    align-self: center !important;
  }
  .align-self-llg-baseline {
    align-self: baseline !important;
  }
  .align-self-llg-stretch {
    align-self: stretch !important;
  }
  .vh-llg-30 {
    height: 30vh !important;
  }
  .vh-llg-40 {
    height: 40vh !important;
  }
  .vh-llg-50 {
    height: 50vh !important;
  }
  .vh-llg-60 {
    height: 60vh !important;
  }
  .vh-llg-65 {
    height: 65vh !important;
  }
  .vh-llg-70 {
    height: 70vh !important;
  }
  .vh-llg-80 {
    height: 80vh !important;
  }
  .vh-llg-100 {
    height: 100vh !important;
  }
  .w-llg-10 {
    width: 10% !important;
  }
  .w-llg-20 {
    width: 20% !important;
  }
  .w-llg-30 {
    width: 30% !important;
  }
  .w-llg-40 {
    width: 40% !important;
  }
  .w-llg-50 {
    width: 50% !important;
  }
  .w-llg-60 {
    width: 60% !important;
  }
  .w-llg-70 {
    width: 70% !important;
  }
  .w-llg-75 {
    width: 75% !important;
  }
  .w-llg-80 {
    width: 80% !important;
  }
  .w-llg-85 {
    width: 85% !important;
  }
  .w-llg-90 {
    width: 90% !important;
  }
  .w-llg-100 {
    width: 100% !important;
  }
  .w-llg-auto {
    width: auto !important;
  }
  .w-llg-20px {
    width: 20px !important;
  }
  .w-llg-80px {
    width: 80px !important;
  }
  .w-llg-180 {
    width: 180px !important;
  }
  .w-llg-200px {
    width: 200px !important;
  }
  .w-llg-cal200 {
    width: calc(100% - 200px) !important;
  }
  .h-llg-25 {
    height: 25% !important;
  }
  .h-llg-50 {
    height: 50% !important;
  }
  .h-llg-75 {
    height: 75% !important;
  }
  .h-llg-100 {
    height: 100% !important;
  }
  .h-llg-20px {
    height: 20px !important;
  }
  .h-llg-27px {
    height: 27px !important;
  }
  .h-llg-auto {
    height: auto !important;
  }
  .h-llg-100vh {
    height: 100vh !important;
  }
  .h-llg-300px {
    height: 300px !important;
  }
  .h-llg-400px {
    height: 400px !important;
  }
  .h-llg-600px {
    height: 600px !important;
  }
  .h-llg-750px {
    height: 750px !important;
  }
  .fs-llg-1 {
    font-size: calc(1.375rem + 1.5vw) !important;
  }
  .fs-llg-2 {
    font-size: calc(1.34375rem + 1.125vw) !important;
  }
  .fs-llg-3 {
    font-size: calc(1.2875rem + 0.45vw) !important;
  }
  .fs-llg-4 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }
  .fs-llg-5 {
    font-size: calc(1.2625rem + 0.15vw) !important;
  }
  .fs-llg-6 {
    font-size: 1.125rem !important;
  }
  .fs-llg-7 {
    font-size: 1rem !important;
  }
  .fs-llg-xl {
    font-size: calc(1.5rem + 3vw) !important;
  }
  .fs-llg-lg {
    font-size: calc(1.3125rem + 0.75vw) !important;
  }
  .fs-llg-md {
    font-size: calc(1.35rem + 1.2vw) !important;
  }
  .fs-llg-sm {
    font-size: 0.875rem !important;
  }
  .fs-llg-xs {
    font-size: 0.75rem !important;
  }
  .fs-llg-xxs {
    font-size: 0.625rem !important;
  }
  .fs-llg-15 {
    font-size: 0.9375rem !important;
  }
  .fs-llg-12 {
    font-size: 0.75rem !important;
  }
  .fs-llg-25 {
    font-size: calc(1.28125rem + 0.375vw) !important;
  }
  .fs-llg-30 {
    font-size: calc(1.3125rem + 0.75vw) !important;
  }
  .fs-llg-55 {
    font-size: calc(1.46875rem + 2.625vw) !important;
  }
  .order-llg-first {
    order: -1 !important;
  }
  .order-llg-0 {
    order: 0 !important;
  }
  .order-llg-1 {
    order: 1 !important;
  }
  .order-llg-2 {
    order: 2 !important;
  }
  .order-llg-3 {
    order: 3 !important;
  }
  .order-llg-4 {
    order: 4 !important;
  }
  .order-llg-5 {
    order: 5 !important;
  }
  .order-llg-last {
    order: 6 !important;
  }
  .order-llg-unset {
    order: unset !important;
  }
  .cursor-llg-auto {
    cursor: auto !important;
  }
  .cursor-llg-pointer {
    cursor: pointer !important;
  }
  .cursor-llg-grab {
    cursor: grab !important;
  }
  .border-llg {
    border: 1px solid #5a5a5a !important;
  }
  .border-llg-0 {
    border: 0 !important;
  }
  .border-top-llg {
    border-top: 1px solid #5a5a5a !important;
  }
  .border-top-llg-0 {
    border-top: 0 !important;
  }
  .border-start-llg {
    border-left: 1px solid #5a5a5a !important;
  }
  .border-start-llg-0 {
    border-left: 0 !important;
  }
  .border-end-llg {
    border-right: 1px solid #5a5a5a !important;
  }
  .border-end-llg-0 {
    border-right: 0 !important;
  }
  .border-bottom-llg {
    border-bottom: 1px solid #5a5a5a !important;
  }
  .border-bottom-llg-0 {
    border-bottom: 0 !important;
  }
  .shadow-llg {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16) !important;
  }
  .shadow-llg-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-llg-lg {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16) !important;
  }
  .shadow-llg-none {
    box-shadow: none !important;
  }
  .text-llg-start {
    text-align: left !important;
  }
  .text-llg-end {
    text-align: right !important;
  }
  .text-llg-center {
    text-align: center !important;
  }
  .text-decoration-llg-none {
    text-decoration: none !important;
  }
  .text-decoration-llg-underline {
    text-decoration: underline !important;
  }
  .text-decoration-llg-line-through {
    text-decoration: line-through !important;
  }
  .text-llg-lowercase {
    text-transform: lowercase !important;
  }
  .text-llg-uppercase {
    text-transform: uppercase !important;
  }
  .text-llg-capitalize {
    text-transform: capitalize !important;
  }
  .position-llg-static {
    position: static !important;
  }
  .position-llg-relative {
    position: relative !important;
  }
  .position-llg-absolute {
    position: absolute !important;
  }
  .position-llg-fixed {
    position: fixed !important;
  }
  .position-llg-sticky {
    position: sticky !important;
  }
  .top-llg-0 {
    top: 0 !important;
  }
  .top-llg-25 {
    top: 25% !important;
  }
  .top-llg-50 {
    top: 50% !important;
  }
  .top-llg-75 {
    top: 75% !important;
  }
  .top-llg-100 {
    top: 100% !important;
  }
  .top-llg-10 {
    top: 10px !important;
  }
  .top-llg-20 {
    top: 20px !important;
  }
  .top-llg-30 {
    top: 30px !important;
  }
  .top-llg-n10 {
    top: -10px !important;
  }
  .top-llg-n20 {
    top: -20px !important;
  }
  .top-llg-n8 {
    top: -8px !important;
  }
  .top-llg-7 {
    top: 7px !important;
  }
  .top-llg-13 {
    top: 13px !important;
  }
  .top-llg-15 {
    top: 15px !important;
  }
  .top-llg-n3 {
    top: -3px !important;
  }
  .top-llg-5 {
    top: 5px !important;
  }
  .top-llg-2 {
    top: 2px !important;
  }
  .bottom-llg-0 {
    bottom: 0 !important;
  }
  .bottom-llg-25 {
    bottom: 25% !important;
  }
  .bottom-llg-50 {
    bottom: 50% !important;
  }
  .bottom-llg-75 {
    bottom: 75% !important;
  }
  .bottom-llg-100 {
    bottom: 100% !important;
  }
  .bottom-llg-10 {
    bottom: 10px !important;
  }
  .bottom-llg-20 {
    bottom: 20px !important;
  }
  .bottom-llg-30 {
    bottom: 30px !important;
  }
  .bottom-llg-n10 {
    bottom: -10px !important;
  }
  .bottom-llg-n20 {
    bottom: -20px !important;
  }
  .bottom-llg-n8 {
    bottom: -8px !important;
  }
  .bottom-llg-7 {
    bottom: 7px !important;
  }
  .bottom-llg-13 {
    bottom: 13px !important;
  }
  .bottom-llg-15 {
    bottom: 15px !important;
  }
  .bottom-llg-n3 {
    bottom: -3px !important;
  }
  .bottom-llg-5 {
    bottom: 5px !important;
  }
  .bottom-llg-2 {
    bottom: 2px !important;
  }
  .end-llg-0 {
    right: 0 !important;
  }
  .end-llg-25 {
    right: 25% !important;
  }
  .end-llg-50 {
    right: 50% !important;
  }
  .end-llg-75 {
    right: 75% !important;
  }
  .end-llg-100 {
    right: 100% !important;
  }
  .end-llg-10 {
    right: 10px !important;
  }
  .end-llg-20 {
    right: 20px !important;
  }
  .end-llg-30 {
    right: 30px !important;
  }
  .end-llg-n10 {
    right: -10px !important;
  }
  .end-llg-n20 {
    right: -20px !important;
  }
  .end-llg-n8 {
    right: -8px !important;
  }
  .end-llg-7 {
    right: 7px !important;
  }
  .end-llg-13 {
    right: 13px !important;
  }
  .end-llg-15 {
    right: 15px !important;
  }
  .end-llg-n3 {
    right: -3px !important;
  }
  .end-llg-5 {
    right: 5px !important;
  }
  .end-llg-2 {
    right: 2px !important;
  }
  .translate-middle-llg {
    transform: translate(-50%, -50%) !important;
  }
  .translate-middle-llg-x {
    transform: translateX(-50%) !important;
  }
  .translate-middle-llg-y {
    transform: translateY(-50%) !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .m-xl-sm {
    margin: 0.3125rem !important;
  }
  .m-xl-3px {
    margin: 3px !important;
  }
  .m-xl-15px {
    margin: 15px !important;
  }
  .m-xl-25px {
    margin: 25px !important;
  }
  .m-xl-35px {
    margin: 35px !important;
  }
  .m-xl-65px {
    margin: 65px !important;
  }
  .m-xl-74px {
    margin: 74px !important;
  }
  .m-xl-700 {
    margin: 700px !important;
  }
  .m-xl-n15 {
    margin: -15px !important;
  }
  .m-xl-n1 {
    margin: -10px !important;
  }
  .m-xl-n5 {
    margin: -5px !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.625rem !important;
  }
  .m-xl-2 {
    margin: 1.25rem !important;
  }
  .m-xl-3 {
    margin: 1.875rem !important;
  }
  .m-xl-4 {
    margin: 2.5rem !important;
  }
  .m-xl-5 {
    margin: 3.125rem !important;
  }
  .m-xl-6 {
    margin: 3.75rem !important;
  }
  .m-xl-7 {
    margin: 4.375rem !important;
  }
  .m-xl-8 {
    margin: 5rem !important;
  }
  .m-xl-9 {
    margin: 5.625rem !important;
  }
  .m-xl-10 {
    margin: 6.25rem !important;
  }
  .m-xl-gutter {
    margin: 2.133rem !important;
  }
  .m-xl-out-container {
    margin: calc(50% - 50vw) !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-sm {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }
  .mx-xl-3px {
    margin-right: 3px !important;
    margin-left: 3px !important;
  }
  .mx-xl-15px {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }
  .mx-xl-25px {
    margin-right: 25px !important;
    margin-left: 25px !important;
  }
  .mx-xl-35px {
    margin-right: 35px !important;
    margin-left: 35px !important;
  }
  .mx-xl-65px {
    margin-right: 65px !important;
    margin-left: 65px !important;
  }
  .mx-xl-74px {
    margin-right: 74px !important;
    margin-left: 74px !important;
  }
  .mx-xl-700 {
    margin-right: 700px !important;
    margin-left: 700px !important;
  }
  .mx-xl-n15 {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }
  .mx-xl-n1 {
    margin-right: -10px !important;
    margin-left: -10px !important;
  }
  .mx-xl-n5 {
    margin-right: -5px !important;
    margin-left: -5px !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-xl-2 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-xl-3 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-xl-4 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-xl-6 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }
  .mx-xl-7 {
    margin-right: 4.375rem !important;
    margin-left: 4.375rem !important;
  }
  .mx-xl-8 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xl-9 {
    margin-right: 5.625rem !important;
    margin-left: 5.625rem !important;
  }
  .mx-xl-10 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important;
  }
  .mx-xl-gutter {
    margin-right: 2.133rem !important;
    margin-left: 2.133rem !important;
  }
  .mx-xl-out-container {
    margin-right: calc(50% - 50vw) !important;
    margin-left: calc(50% - 50vw) !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-sm {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  .my-xl-3px {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }
  .my-xl-15px {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .my-xl-25px {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .my-xl-35px {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .my-xl-65px {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }
  .my-xl-74px {
    margin-top: 74px !important;
    margin-bottom: 74px !important;
  }
  .my-xl-700 {
    margin-top: 700px !important;
    margin-bottom: 700px !important;
  }
  .my-xl-n15 {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }
  .my-xl-n1 {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }
  .my-xl-n5 {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-xl-2 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-xl-3 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-xl-4 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-xl-6 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }
  .my-xl-7 {
    margin-top: 4.375rem !important;
    margin-bottom: 4.375rem !important;
  }
  .my-xl-8 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xl-9 {
    margin-top: 5.625rem !important;
    margin-bottom: 5.625rem !important;
  }
  .my-xl-10 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
  }
  .my-xl-gutter {
    margin-top: 2.133rem !important;
    margin-bottom: 2.133rem !important;
  }
  .my-xl-out-container {
    margin-top: calc(50% - 50vw) !important;
    margin-bottom: calc(50% - 50vw) !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-sm {
    margin-top: 0.3125rem !important;
  }
  .mt-xl-3px {
    margin-top: 3px !important;
  }
  .mt-xl-15px {
    margin-top: 15px !important;
  }
  .mt-xl-25px {
    margin-top: 25px !important;
  }
  .mt-xl-35px {
    margin-top: 35px !important;
  }
  .mt-xl-65px {
    margin-top: 65px !important;
  }
  .mt-xl-74px {
    margin-top: 74px !important;
  }
  .mt-xl-700 {
    margin-top: 700px !important;
  }
  .mt-xl-n15 {
    margin-top: -15px !important;
  }
  .mt-xl-n1 {
    margin-top: -10px !important;
  }
  .mt-xl-n5 {
    margin-top: -5px !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.625rem !important;
  }
  .mt-xl-2 {
    margin-top: 1.25rem !important;
  }
  .mt-xl-3 {
    margin-top: 1.875rem !important;
  }
  .mt-xl-4 {
    margin-top: 2.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3.125rem !important;
  }
  .mt-xl-6 {
    margin-top: 3.75rem !important;
  }
  .mt-xl-7 {
    margin-top: 4.375rem !important;
  }
  .mt-xl-8 {
    margin-top: 5rem !important;
  }
  .mt-xl-9 {
    margin-top: 5.625rem !important;
  }
  .mt-xl-10 {
    margin-top: 6.25rem !important;
  }
  .mt-xl-gutter {
    margin-top: 2.133rem !important;
  }
  .mt-xl-out-container {
    margin-top: calc(50% - 50vw) !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-sm {
    margin-right: 0.3125rem !important;
  }
  .me-xl-3px {
    margin-right: 3px !important;
  }
  .me-xl-15px {
    margin-right: 15px !important;
  }
  .me-xl-25px {
    margin-right: 25px !important;
  }
  .me-xl-35px {
    margin-right: 35px !important;
  }
  .me-xl-65px {
    margin-right: 65px !important;
  }
  .me-xl-74px {
    margin-right: 74px !important;
  }
  .me-xl-700 {
    margin-right: 700px !important;
  }
  .me-xl-n15 {
    margin-right: -15px !important;
  }
  .me-xl-n1 {
    margin-right: -10px !important;
  }
  .me-xl-n5 {
    margin-right: -5px !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.625rem !important;
  }
  .me-xl-2 {
    margin-right: 1.25rem !important;
  }
  .me-xl-3 {
    margin-right: 1.875rem !important;
  }
  .me-xl-4 {
    margin-right: 2.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3.125rem !important;
  }
  .me-xl-6 {
    margin-right: 3.75rem !important;
  }
  .me-xl-7 {
    margin-right: 4.375rem !important;
  }
  .me-xl-8 {
    margin-right: 5rem !important;
  }
  .me-xl-9 {
    margin-right: 5.625rem !important;
  }
  .me-xl-10 {
    margin-right: 6.25rem !important;
  }
  .me-xl-gutter {
    margin-right: 2.133rem !important;
  }
  .me-xl-out-container {
    margin-right: calc(50% - 50vw) !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-sm {
    margin-bottom: 0.3125rem !important;
  }
  .mb-xl-3px {
    margin-bottom: 3px !important;
  }
  .mb-xl-15px {
    margin-bottom: 15px !important;
  }
  .mb-xl-25px {
    margin-bottom: 25px !important;
  }
  .mb-xl-35px {
    margin-bottom: 35px !important;
  }
  .mb-xl-65px {
    margin-bottom: 65px !important;
  }
  .mb-xl-74px {
    margin-bottom: 74px !important;
  }
  .mb-xl-700 {
    margin-bottom: 700px !important;
  }
  .mb-xl-n15 {
    margin-bottom: -15px !important;
  }
  .mb-xl-n1 {
    margin-bottom: -10px !important;
  }
  .mb-xl-n5 {
    margin-bottom: -5px !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.625rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 1.25rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1.875rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3.125rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 3.75rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 4.375rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 5rem !important;
  }
  .mb-xl-9 {
    margin-bottom: 5.625rem !important;
  }
  .mb-xl-10 {
    margin-bottom: 6.25rem !important;
  }
  .mb-xl-gutter {
    margin-bottom: 2.133rem !important;
  }
  .mb-xl-out-container {
    margin-bottom: calc(50% - 50vw) !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-sm {
    margin-left: 0.3125rem !important;
  }
  .ms-xl-3px {
    margin-left: 3px !important;
  }
  .ms-xl-15px {
    margin-left: 15px !important;
  }
  .ms-xl-25px {
    margin-left: 25px !important;
  }
  .ms-xl-35px {
    margin-left: 35px !important;
  }
  .ms-xl-65px {
    margin-left: 65px !important;
  }
  .ms-xl-74px {
    margin-left: 74px !important;
  }
  .ms-xl-700 {
    margin-left: 700px !important;
  }
  .ms-xl-n15 {
    margin-left: -15px !important;
  }
  .ms-xl-n1 {
    margin-left: -10px !important;
  }
  .ms-xl-n5 {
    margin-left: -5px !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.625rem !important;
  }
  .ms-xl-2 {
    margin-left: 1.25rem !important;
  }
  .ms-xl-3 {
    margin-left: 1.875rem !important;
  }
  .ms-xl-4 {
    margin-left: 2.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3.125rem !important;
  }
  .ms-xl-6 {
    margin-left: 3.75rem !important;
  }
  .ms-xl-7 {
    margin-left: 4.375rem !important;
  }
  .ms-xl-8 {
    margin-left: 5rem !important;
  }
  .ms-xl-9 {
    margin-left: 5.625rem !important;
  }
  .ms-xl-10 {
    margin-left: 6.25rem !important;
  }
  .ms-xl-gutter {
    margin-left: 2.133rem !important;
  }
  .ms-xl-out-container {
    margin-left: calc(50% - 50vw) !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-sm {
    padding: 0.3125rem !important;
  }
  .p-xl-3px {
    padding: 3px !important;
  }
  .p-xl-15px {
    padding: 15px !important;
  }
  .p-xl-25px {
    padding: 25px !important;
  }
  .p-xl-35px {
    padding: 35px !important;
  }
  .p-xl-65px {
    padding: 65px !important;
  }
  .p-xl-74px {
    padding: 74px !important;
  }
  .p-xl-700 {
    padding: 700px !important;
  }
  .p-xl-n15 {
    padding: -15px !important;
  }
  .p-xl-n1 {
    padding: -10px !important;
  }
  .p-xl-n5 {
    padding: -5px !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.625rem !important;
  }
  .p-xl-2 {
    padding: 1.25rem !important;
  }
  .p-xl-3 {
    padding: 1.875rem !important;
  }
  .p-xl-4 {
    padding: 2.5rem !important;
  }
  .p-xl-5 {
    padding: 3.125rem !important;
  }
  .p-xl-6 {
    padding: 3.75rem !important;
  }
  .p-xl-7 {
    padding: 4.375rem !important;
  }
  .p-xl-8 {
    padding: 5rem !important;
  }
  .p-xl-9 {
    padding: 5.625rem !important;
  }
  .p-xl-10 {
    padding: 6.25rem !important;
  }
  .p-xl-gutter {
    padding: 2.133rem !important;
  }
  .p-xl-out-container {
    padding: calc(50% - 50vw) !important;
  }
  .px-xl-sm {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }
  .px-xl-3px {
    padding-right: 3px !important;
    padding-left: 3px !important;
  }
  .px-xl-15px {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  .px-xl-25px {
    padding-right: 25px !important;
    padding-left: 25px !important;
  }
  .px-xl-35px {
    padding-right: 35px !important;
    padding-left: 35px !important;
  }
  .px-xl-65px {
    padding-right: 65px !important;
    padding-left: 65px !important;
  }
  .px-xl-74px {
    padding-right: 74px !important;
    padding-left: 74px !important;
  }
  .px-xl-700 {
    padding-right: 700px !important;
    padding-left: 700px !important;
  }
  .px-xl-n15 {
    padding-right: -15px !important;
    padding-left: -15px !important;
  }
  .px-xl-n1 {
    padding-right: -10px !important;
    padding-left: -10px !important;
  }
  .px-xl-n5 {
    padding-right: -5px !important;
    padding-left: -5px !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-xl-2 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-xl-3 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-xl-4 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .px-xl-6 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }
  .px-xl-7 {
    padding-right: 4.375rem !important;
    padding-left: 4.375rem !important;
  }
  .px-xl-8 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-xl-9 {
    padding-right: 5.625rem !important;
    padding-left: 5.625rem !important;
  }
  .px-xl-10 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important;
  }
  .px-xl-gutter {
    padding-right: 2.133rem !important;
    padding-left: 2.133rem !important;
  }
  .px-xl-out-container {
    padding-right: calc(50% - 50vw) !important;
    padding-left: calc(50% - 50vw) !important;
  }
  .py-xl-sm {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  .py-xl-3px {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
  .py-xl-15px {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .py-xl-25px {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .py-xl-35px {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .py-xl-65px {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  .py-xl-74px {
    padding-top: 74px !important;
    padding-bottom: 74px !important;
  }
  .py-xl-700 {
    padding-top: 700px !important;
    padding-bottom: 700px !important;
  }
  .py-xl-n15 {
    padding-top: -15px !important;
    padding-bottom: -15px !important;
  }
  .py-xl-n1 {
    padding-top: -10px !important;
    padding-bottom: -10px !important;
  }
  .py-xl-n5 {
    padding-top: -5px !important;
    padding-bottom: -5px !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-xl-2 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-xl-3 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-xl-4 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .py-xl-6 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .py-xl-7 {
    padding-top: 4.375rem !important;
    padding-bottom: 4.375rem !important;
  }
  .py-xl-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-xl-9 {
    padding-top: 5.625rem !important;
    padding-bottom: 5.625rem !important;
  }
  .py-xl-10 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }
  .py-xl-gutter {
    padding-top: 2.133rem !important;
    padding-bottom: 2.133rem !important;
  }
  .py-xl-out-container {
    padding-top: calc(50% - 50vw) !important;
    padding-bottom: calc(50% - 50vw) !important;
  }
  .pt-xl-sm {
    padding-top: 0.3125rem !important;
  }
  .pt-xl-3px {
    padding-top: 3px !important;
  }
  .pt-xl-15px {
    padding-top: 15px !important;
  }
  .pt-xl-25px {
    padding-top: 25px !important;
  }
  .pt-xl-35px {
    padding-top: 35px !important;
  }
  .pt-xl-65px {
    padding-top: 65px !important;
  }
  .pt-xl-74px {
    padding-top: 74px !important;
  }
  .pt-xl-700 {
    padding-top: 700px !important;
  }
  .pt-xl-n15 {
    padding-top: -15px !important;
  }
  .pt-xl-n1 {
    padding-top: -10px !important;
  }
  .pt-xl-n5 {
    padding-top: -5px !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.625rem !important;
  }
  .pt-xl-2 {
    padding-top: 1.25rem !important;
  }
  .pt-xl-3 {
    padding-top: 1.875rem !important;
  }
  .pt-xl-4 {
    padding-top: 2.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3.125rem !important;
  }
  .pt-xl-6 {
    padding-top: 3.75rem !important;
  }
  .pt-xl-7 {
    padding-top: 4.375rem !important;
  }
  .pt-xl-8 {
    padding-top: 5rem !important;
  }
  .pt-xl-9 {
    padding-top: 5.625rem !important;
  }
  .pt-xl-10 {
    padding-top: 6.25rem !important;
  }
  .pt-xl-gutter {
    padding-top: 2.133rem !important;
  }
  .pt-xl-out-container {
    padding-top: calc(50% - 50vw) !important;
  }
  .pe-xl-sm {
    padding-right: 0.3125rem !important;
  }
  .pe-xl-3px {
    padding-right: 3px !important;
  }
  .pe-xl-15px {
    padding-right: 15px !important;
  }
  .pe-xl-25px {
    padding-right: 25px !important;
  }
  .pe-xl-35px {
    padding-right: 35px !important;
  }
  .pe-xl-65px {
    padding-right: 65px !important;
  }
  .pe-xl-74px {
    padding-right: 74px !important;
  }
  .pe-xl-700 {
    padding-right: 700px !important;
  }
  .pe-xl-n15 {
    padding-right: -15px !important;
  }
  .pe-xl-n1 {
    padding-right: -10px !important;
  }
  .pe-xl-n5 {
    padding-right: -5px !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.625rem !important;
  }
  .pe-xl-2 {
    padding-right: 1.25rem !important;
  }
  .pe-xl-3 {
    padding-right: 1.875rem !important;
  }
  .pe-xl-4 {
    padding-right: 2.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3.125rem !important;
  }
  .pe-xl-6 {
    padding-right: 3.75rem !important;
  }
  .pe-xl-7 {
    padding-right: 4.375rem !important;
  }
  .pe-xl-8 {
    padding-right: 5rem !important;
  }
  .pe-xl-9 {
    padding-right: 5.625rem !important;
  }
  .pe-xl-10 {
    padding-right: 6.25rem !important;
  }
  .pe-xl-gutter {
    padding-right: 2.133rem !important;
  }
  .pe-xl-out-container {
    padding-right: calc(50% - 50vw) !important;
  }
  .pb-xl-sm {
    padding-bottom: 0.3125rem !important;
  }
  .pb-xl-3px {
    padding-bottom: 3px !important;
  }
  .pb-xl-15px {
    padding-bottom: 15px !important;
  }
  .pb-xl-25px {
    padding-bottom: 25px !important;
  }
  .pb-xl-35px {
    padding-bottom: 35px !important;
  }
  .pb-xl-65px {
    padding-bottom: 65px !important;
  }
  .pb-xl-74px {
    padding-bottom: 74px !important;
  }
  .pb-xl-700 {
    padding-bottom: 700px !important;
  }
  .pb-xl-n15 {
    padding-bottom: -15px !important;
  }
  .pb-xl-n1 {
    padding-bottom: -10px !important;
  }
  .pb-xl-n5 {
    padding-bottom: -5px !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.625rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 1.25rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1.875rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3.125rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 3.75rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 4.375rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 5rem !important;
  }
  .pb-xl-9 {
    padding-bottom: 5.625rem !important;
  }
  .pb-xl-10 {
    padding-bottom: 6.25rem !important;
  }
  .pb-xl-gutter {
    padding-bottom: 2.133rem !important;
  }
  .pb-xl-out-container {
    padding-bottom: calc(50% - 50vw) !important;
  }
  .ps-xl-sm {
    padding-left: 0.3125rem !important;
  }
  .ps-xl-3px {
    padding-left: 3px !important;
  }
  .ps-xl-15px {
    padding-left: 15px !important;
  }
  .ps-xl-25px {
    padding-left: 25px !important;
  }
  .ps-xl-35px {
    padding-left: 35px !important;
  }
  .ps-xl-65px {
    padding-left: 65px !important;
  }
  .ps-xl-74px {
    padding-left: 74px !important;
  }
  .ps-xl-700 {
    padding-left: 700px !important;
  }
  .ps-xl-n15 {
    padding-left: -15px !important;
  }
  .ps-xl-n1 {
    padding-left: -10px !important;
  }
  .ps-xl-n5 {
    padding-left: -5px !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.625rem !important;
  }
  .ps-xl-2 {
    padding-left: 1.25rem !important;
  }
  .ps-xl-3 {
    padding-left: 1.875rem !important;
  }
  .ps-xl-4 {
    padding-left: 2.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3.125rem !important;
  }
  .ps-xl-6 {
    padding-left: 3.75rem !important;
  }
  .ps-xl-7 {
    padding-left: 4.375rem !important;
  }
  .ps-xl-8 {
    padding-left: 5rem !important;
  }
  .ps-xl-9 {
    padding-left: 5.625rem !important;
  }
  .ps-xl-10 {
    padding-left: 6.25rem !important;
  }
  .ps-xl-gutter {
    padding-left: 2.133rem !important;
  }
  .ps-xl-out-container {
    padding-left: calc(50% - 50vw) !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xl-sm {
    gap: 0.3125rem !important;
  }
  .gap-xl-3px {
    gap: 3px !important;
  }
  .gap-xl-15px {
    gap: 15px !important;
  }
  .gap-xl-25px {
    gap: 25px !important;
  }
  .gap-xl-35px {
    gap: 35px !important;
  }
  .gap-xl-65px {
    gap: 65px !important;
  }
  .gap-xl-74px {
    gap: 74px !important;
  }
  .gap-xl-700 {
    gap: 700px !important;
  }
  .gap-xl-n15 {
    gap: -15px !important;
  }
  .gap-xl-n1 {
    gap: -10px !important;
  }
  .gap-xl-n5 {
    gap: -5px !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.625rem !important;
  }
  .gap-xl-2 {
    gap: 1.25rem !important;
  }
  .gap-xl-3 {
    gap: 1.875rem !important;
  }
  .gap-xl-4 {
    gap: 2.5rem !important;
  }
  .gap-xl-5 {
    gap: 3.125rem !important;
  }
  .gap-xl-6 {
    gap: 3.75rem !important;
  }
  .gap-xl-7 {
    gap: 4.375rem !important;
  }
  .gap-xl-8 {
    gap: 5rem !important;
  }
  .gap-xl-9 {
    gap: 5.625rem !important;
  }
  .gap-xl-10 {
    gap: 6.25rem !important;
  }
  .gap-xl-gutter {
    gap: 2.133rem !important;
  }
  .gap-xl-out-container {
    gap: calc(50% - 50vw) !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .vh-xl-30 {
    height: 30vh !important;
  }
  .vh-xl-40 {
    height: 40vh !important;
  }
  .vh-xl-50 {
    height: 50vh !important;
  }
  .vh-xl-60 {
    height: 60vh !important;
  }
  .vh-xl-65 {
    height: 65vh !important;
  }
  .vh-xl-70 {
    height: 70vh !important;
  }
  .vh-xl-80 {
    height: 80vh !important;
  }
  .vh-xl-100 {
    height: 100vh !important;
  }
  .w-xl-10 {
    width: 10% !important;
  }
  .w-xl-20 {
    width: 20% !important;
  }
  .w-xl-30 {
    width: 30% !important;
  }
  .w-xl-40 {
    width: 40% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-60 {
    width: 60% !important;
  }
  .w-xl-70 {
    width: 70% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-80 {
    width: 80% !important;
  }
  .w-xl-85 {
    width: 85% !important;
  }
  .w-xl-90 {
    width: 90% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
  .w-xl-20px {
    width: 20px !important;
  }
  .w-xl-80px {
    width: 80px !important;
  }
  .w-xl-180 {
    width: 180px !important;
  }
  .w-xl-200px {
    width: 200px !important;
  }
  .w-xl-cal200 {
    width: calc(100% - 200px) !important;
  }
  .h-xl-25 {
    height: 25% !important;
  }
  .h-xl-50 {
    height: 50% !important;
  }
  .h-xl-75 {
    height: 75% !important;
  }
  .h-xl-100 {
    height: 100% !important;
  }
  .h-xl-20px {
    height: 20px !important;
  }
  .h-xl-27px {
    height: 27px !important;
  }
  .h-xl-auto {
    height: auto !important;
  }
  .h-xl-100vh {
    height: 100vh !important;
  }
  .h-xl-300px {
    height: 300px !important;
  }
  .h-xl-400px {
    height: 400px !important;
  }
  .h-xl-600px {
    height: 600px !important;
  }
  .h-xl-750px {
    height: 750px !important;
  }
  .fs-xl-1 {
    font-size: calc(1.375rem + 1.5vw) !important;
  }
  .fs-xl-2 {
    font-size: calc(1.34375rem + 1.125vw) !important;
  }
  .fs-xl-3 {
    font-size: calc(1.2875rem + 0.45vw) !important;
  }
  .fs-xl-4 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }
  .fs-xl-5 {
    font-size: calc(1.2625rem + 0.15vw) !important;
  }
  .fs-xl-6 {
    font-size: 1.125rem !important;
  }
  .fs-xl-7 {
    font-size: 1rem !important;
  }
  .fs-xl-xl {
    font-size: calc(1.5rem + 3vw) !important;
  }
  .fs-xl-lg {
    font-size: calc(1.3125rem + 0.75vw) !important;
  }
  .fs-xl-md {
    font-size: calc(1.35rem + 1.2vw) !important;
  }
  .fs-xl-sm {
    font-size: 0.875rem !important;
  }
  .fs-xl-xs {
    font-size: 0.75rem !important;
  }
  .fs-xl-xxs {
    font-size: 0.625rem !important;
  }
  .fs-xl-15 {
    font-size: 0.9375rem !important;
  }
  .fs-xl-12 {
    font-size: 0.75rem !important;
  }
  .fs-xl-25 {
    font-size: calc(1.28125rem + 0.375vw) !important;
  }
  .fs-xl-30 {
    font-size: calc(1.3125rem + 0.75vw) !important;
  }
  .fs-xl-55 {
    font-size: calc(1.46875rem + 2.625vw) !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .order-xl-unset {
    order: unset !important;
  }
  .cursor-xl-auto {
    cursor: auto !important;
  }
  .cursor-xl-pointer {
    cursor: pointer !important;
  }
  .cursor-xl-grab {
    cursor: grab !important;
  }
  .border-xl {
    border: 1px solid #5a5a5a !important;
  }
  .border-xl-0 {
    border: 0 !important;
  }
  .border-top-xl {
    border-top: 1px solid #5a5a5a !important;
  }
  .border-top-xl-0 {
    border-top: 0 !important;
  }
  .border-start-xl {
    border-left: 1px solid #5a5a5a !important;
  }
  .border-start-xl-0 {
    border-left: 0 !important;
  }
  .border-end-xl {
    border-right: 1px solid #5a5a5a !important;
  }
  .border-end-xl-0 {
    border-right: 0 !important;
  }
  .border-bottom-xl {
    border-bottom: 1px solid #5a5a5a !important;
  }
  .border-bottom-xl-0 {
    border-bottom: 0 !important;
  }
  .shadow-xl {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16) !important;
  }
  .shadow-xl-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-xl-lg {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16) !important;
  }
  .shadow-xl-none {
    box-shadow: none !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
  .text-decoration-xl-none {
    text-decoration: none !important;
  }
  .text-decoration-xl-underline {
    text-decoration: underline !important;
  }
  .text-decoration-xl-line-through {
    text-decoration: line-through !important;
  }
  .text-xl-lowercase {
    text-transform: lowercase !important;
  }
  .text-xl-uppercase {
    text-transform: uppercase !important;
  }
  .text-xl-capitalize {
    text-transform: capitalize !important;
  }
  .position-xl-static {
    position: static !important;
  }
  .position-xl-relative {
    position: relative !important;
  }
  .position-xl-absolute {
    position: absolute !important;
  }
  .position-xl-fixed {
    position: fixed !important;
  }
  .position-xl-sticky {
    position: sticky !important;
  }
  .top-xl-0 {
    top: 0 !important;
  }
  .top-xl-25 {
    top: 25% !important;
  }
  .top-xl-50 {
    top: 50% !important;
  }
  .top-xl-75 {
    top: 75% !important;
  }
  .top-xl-100 {
    top: 100% !important;
  }
  .top-xl-10 {
    top: 10px !important;
  }
  .top-xl-20 {
    top: 20px !important;
  }
  .top-xl-30 {
    top: 30px !important;
  }
  .top-xl-n10 {
    top: -10px !important;
  }
  .top-xl-n20 {
    top: -20px !important;
  }
  .top-xl-n8 {
    top: -8px !important;
  }
  .top-xl-7 {
    top: 7px !important;
  }
  .top-xl-13 {
    top: 13px !important;
  }
  .top-xl-15 {
    top: 15px !important;
  }
  .top-xl-n3 {
    top: -3px !important;
  }
  .top-xl-5 {
    top: 5px !important;
  }
  .top-xl-2 {
    top: 2px !important;
  }
  .bottom-xl-0 {
    bottom: 0 !important;
  }
  .bottom-xl-25 {
    bottom: 25% !important;
  }
  .bottom-xl-50 {
    bottom: 50% !important;
  }
  .bottom-xl-75 {
    bottom: 75% !important;
  }
  .bottom-xl-100 {
    bottom: 100% !important;
  }
  .bottom-xl-10 {
    bottom: 10px !important;
  }
  .bottom-xl-20 {
    bottom: 20px !important;
  }
  .bottom-xl-30 {
    bottom: 30px !important;
  }
  .bottom-xl-n10 {
    bottom: -10px !important;
  }
  .bottom-xl-n20 {
    bottom: -20px !important;
  }
  .bottom-xl-n8 {
    bottom: -8px !important;
  }
  .bottom-xl-7 {
    bottom: 7px !important;
  }
  .bottom-xl-13 {
    bottom: 13px !important;
  }
  .bottom-xl-15 {
    bottom: 15px !important;
  }
  .bottom-xl-n3 {
    bottom: -3px !important;
  }
  .bottom-xl-5 {
    bottom: 5px !important;
  }
  .bottom-xl-2 {
    bottom: 2px !important;
  }
  .end-xl-0 {
    right: 0 !important;
  }
  .end-xl-25 {
    right: 25% !important;
  }
  .end-xl-50 {
    right: 50% !important;
  }
  .end-xl-75 {
    right: 75% !important;
  }
  .end-xl-100 {
    right: 100% !important;
  }
  .end-xl-10 {
    right: 10px !important;
  }
  .end-xl-20 {
    right: 20px !important;
  }
  .end-xl-30 {
    right: 30px !important;
  }
  .end-xl-n10 {
    right: -10px !important;
  }
  .end-xl-n20 {
    right: -20px !important;
  }
  .end-xl-n8 {
    right: -8px !important;
  }
  .end-xl-7 {
    right: 7px !important;
  }
  .end-xl-13 {
    right: 13px !important;
  }
  .end-xl-15 {
    right: 15px !important;
  }
  .end-xl-n3 {
    right: -3px !important;
  }
  .end-xl-5 {
    right: 5px !important;
  }
  .end-xl-2 {
    right: 2px !important;
  }
  .translate-middle-xl {
    transform: translate(-50%, -50%) !important;
  }
  .translate-middle-xl-x {
    transform: translateX(-50%) !important;
  }
  .translate-middle-xl-y {
    transform: translateY(-50%) !important;
  }
}

@media (min-width: 1400px) {
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .m-xxl-sm {
    margin: 0.3125rem !important;
  }
  .m-xxl-3px {
    margin: 3px !important;
  }
  .m-xxl-15px {
    margin: 15px !important;
  }
  .m-xxl-25px {
    margin: 25px !important;
  }
  .m-xxl-35px {
    margin: 35px !important;
  }
  .m-xxl-65px {
    margin: 65px !important;
  }
  .m-xxl-74px {
    margin: 74px !important;
  }
  .m-xxl-700 {
    margin: 700px !important;
  }
  .m-xxl-n15 {
    margin: -15px !important;
  }
  .m-xxl-n1 {
    margin: -10px !important;
  }
  .m-xxl-n5 {
    margin: -5px !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.625rem !important;
  }
  .m-xxl-2 {
    margin: 1.25rem !important;
  }
  .m-xxl-3 {
    margin: 1.875rem !important;
  }
  .m-xxl-4 {
    margin: 2.5rem !important;
  }
  .m-xxl-5 {
    margin: 3.125rem !important;
  }
  .m-xxl-6 {
    margin: 3.75rem !important;
  }
  .m-xxl-7 {
    margin: 4.375rem !important;
  }
  .m-xxl-8 {
    margin: 5rem !important;
  }
  .m-xxl-9 {
    margin: 5.625rem !important;
  }
  .m-xxl-10 {
    margin: 6.25rem !important;
  }
  .m-xxl-gutter {
    margin: 2.133rem !important;
  }
  .m-xxl-out-container {
    margin: calc(50% - 50vw) !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-sm {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }
  .mx-xxl-3px {
    margin-right: 3px !important;
    margin-left: 3px !important;
  }
  .mx-xxl-15px {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }
  .mx-xxl-25px {
    margin-right: 25px !important;
    margin-left: 25px !important;
  }
  .mx-xxl-35px {
    margin-right: 35px !important;
    margin-left: 35px !important;
  }
  .mx-xxl-65px {
    margin-right: 65px !important;
    margin-left: 65px !important;
  }
  .mx-xxl-74px {
    margin-right: 74px !important;
    margin-left: 74px !important;
  }
  .mx-xxl-700 {
    margin-right: 700px !important;
    margin-left: 700px !important;
  }
  .mx-xxl-n15 {
    margin-right: -15px !important;
    margin-left: -15px !important;
  }
  .mx-xxl-n1 {
    margin-right: -10px !important;
    margin-left: -10px !important;
  }
  .mx-xxl-n5 {
    margin-right: -5px !important;
    margin-left: -5px !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-xxl-2 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-xxl-4 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-xxl-6 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }
  .mx-xxl-7 {
    margin-right: 4.375rem !important;
    margin-left: 4.375rem !important;
  }
  .mx-xxl-8 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-xxl-9 {
    margin-right: 5.625rem !important;
    margin-left: 5.625rem !important;
  }
  .mx-xxl-10 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important;
  }
  .mx-xxl-gutter {
    margin-right: 2.133rem !important;
    margin-left: 2.133rem !important;
  }
  .mx-xxl-out-container {
    margin-right: calc(50% - 50vw) !important;
    margin-left: calc(50% - 50vw) !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-sm {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  .my-xxl-3px {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }
  .my-xxl-15px {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .my-xxl-25px {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .my-xxl-35px {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .my-xxl-65px {
    margin-top: 65px !important;
    margin-bottom: 65px !important;
  }
  .my-xxl-74px {
    margin-top: 74px !important;
    margin-bottom: 74px !important;
  }
  .my-xxl-700 {
    margin-top: 700px !important;
    margin-bottom: 700px !important;
  }
  .my-xxl-n15 {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }
  .my-xxl-n1 {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }
  .my-xxl-n5 {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-xxl-2 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-xxl-3 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-xxl-4 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-xxl-6 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }
  .my-xxl-7 {
    margin-top: 4.375rem !important;
    margin-bottom: 4.375rem !important;
  }
  .my-xxl-8 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-xxl-9 {
    margin-top: 5.625rem !important;
    margin-bottom: 5.625rem !important;
  }
  .my-xxl-10 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
  }
  .my-xxl-gutter {
    margin-top: 2.133rem !important;
    margin-bottom: 2.133rem !important;
  }
  .my-xxl-out-container {
    margin-top: calc(50% - 50vw) !important;
    margin-bottom: calc(50% - 50vw) !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-sm {
    margin-top: 0.3125rem !important;
  }
  .mt-xxl-3px {
    margin-top: 3px !important;
  }
  .mt-xxl-15px {
    margin-top: 15px !important;
  }
  .mt-xxl-25px {
    margin-top: 25px !important;
  }
  .mt-xxl-35px {
    margin-top: 35px !important;
  }
  .mt-xxl-65px {
    margin-top: 65px !important;
  }
  .mt-xxl-74px {
    margin-top: 74px !important;
  }
  .mt-xxl-700 {
    margin-top: 700px !important;
  }
  .mt-xxl-n15 {
    margin-top: -15px !important;
  }
  .mt-xxl-n1 {
    margin-top: -10px !important;
  }
  .mt-xxl-n5 {
    margin-top: -5px !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.625rem !important;
  }
  .mt-xxl-2 {
    margin-top: 1.25rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1.875rem !important;
  }
  .mt-xxl-4 {
    margin-top: 2.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3.125rem !important;
  }
  .mt-xxl-6 {
    margin-top: 3.75rem !important;
  }
  .mt-xxl-7 {
    margin-top: 4.375rem !important;
  }
  .mt-xxl-8 {
    margin-top: 5rem !important;
  }
  .mt-xxl-9 {
    margin-top: 5.625rem !important;
  }
  .mt-xxl-10 {
    margin-top: 6.25rem !important;
  }
  .mt-xxl-gutter {
    margin-top: 2.133rem !important;
  }
  .mt-xxl-out-container {
    margin-top: calc(50% - 50vw) !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-sm {
    margin-right: 0.3125rem !important;
  }
  .me-xxl-3px {
    margin-right: 3px !important;
  }
  .me-xxl-15px {
    margin-right: 15px !important;
  }
  .me-xxl-25px {
    margin-right: 25px !important;
  }
  .me-xxl-35px {
    margin-right: 35px !important;
  }
  .me-xxl-65px {
    margin-right: 65px !important;
  }
  .me-xxl-74px {
    margin-right: 74px !important;
  }
  .me-xxl-700 {
    margin-right: 700px !important;
  }
  .me-xxl-n15 {
    margin-right: -15px !important;
  }
  .me-xxl-n1 {
    margin-right: -10px !important;
  }
  .me-xxl-n5 {
    margin-right: -5px !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.625rem !important;
  }
  .me-xxl-2 {
    margin-right: 1.25rem !important;
  }
  .me-xxl-3 {
    margin-right: 1.875rem !important;
  }
  .me-xxl-4 {
    margin-right: 2.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3.125rem !important;
  }
  .me-xxl-6 {
    margin-right: 3.75rem !important;
  }
  .me-xxl-7 {
    margin-right: 4.375rem !important;
  }
  .me-xxl-8 {
    margin-right: 5rem !important;
  }
  .me-xxl-9 {
    margin-right: 5.625rem !important;
  }
  .me-xxl-10 {
    margin-right: 6.25rem !important;
  }
  .me-xxl-gutter {
    margin-right: 2.133rem !important;
  }
  .me-xxl-out-container {
    margin-right: calc(50% - 50vw) !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-sm {
    margin-bottom: 0.3125rem !important;
  }
  .mb-xxl-3px {
    margin-bottom: 3px !important;
  }
  .mb-xxl-15px {
    margin-bottom: 15px !important;
  }
  .mb-xxl-25px {
    margin-bottom: 25px !important;
  }
  .mb-xxl-35px {
    margin-bottom: 35px !important;
  }
  .mb-xxl-65px {
    margin-bottom: 65px !important;
  }
  .mb-xxl-74px {
    margin-bottom: 74px !important;
  }
  .mb-xxl-700 {
    margin-bottom: 700px !important;
  }
  .mb-xxl-n15 {
    margin-bottom: -15px !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -10px !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -5px !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.625rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 1.25rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1.875rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3.125rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 3.75rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 4.375rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 5rem !important;
  }
  .mb-xxl-9 {
    margin-bottom: 5.625rem !important;
  }
  .mb-xxl-10 {
    margin-bottom: 6.25rem !important;
  }
  .mb-xxl-gutter {
    margin-bottom: 2.133rem !important;
  }
  .mb-xxl-out-container {
    margin-bottom: calc(50% - 50vw) !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-sm {
    margin-left: 0.3125rem !important;
  }
  .ms-xxl-3px {
    margin-left: 3px !important;
  }
  .ms-xxl-15px {
    margin-left: 15px !important;
  }
  .ms-xxl-25px {
    margin-left: 25px !important;
  }
  .ms-xxl-35px {
    margin-left: 35px !important;
  }
  .ms-xxl-65px {
    margin-left: 65px !important;
  }
  .ms-xxl-74px {
    margin-left: 74px !important;
  }
  .ms-xxl-700 {
    margin-left: 700px !important;
  }
  .ms-xxl-n15 {
    margin-left: -15px !important;
  }
  .ms-xxl-n1 {
    margin-left: -10px !important;
  }
  .ms-xxl-n5 {
    margin-left: -5px !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.625rem !important;
  }
  .ms-xxl-2 {
    margin-left: 1.25rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1.875rem !important;
  }
  .ms-xxl-4 {
    margin-left: 2.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3.125rem !important;
  }
  .ms-xxl-6 {
    margin-left: 3.75rem !important;
  }
  .ms-xxl-7 {
    margin-left: 4.375rem !important;
  }
  .ms-xxl-8 {
    margin-left: 5rem !important;
  }
  .ms-xxl-9 {
    margin-left: 5.625rem !important;
  }
  .ms-xxl-10 {
    margin-left: 6.25rem !important;
  }
  .ms-xxl-gutter {
    margin-left: 2.133rem !important;
  }
  .ms-xxl-out-container {
    margin-left: calc(50% - 50vw) !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-sm {
    padding: 0.3125rem !important;
  }
  .p-xxl-3px {
    padding: 3px !important;
  }
  .p-xxl-15px {
    padding: 15px !important;
  }
  .p-xxl-25px {
    padding: 25px !important;
  }
  .p-xxl-35px {
    padding: 35px !important;
  }
  .p-xxl-65px {
    padding: 65px !important;
  }
  .p-xxl-74px {
    padding: 74px !important;
  }
  .p-xxl-700 {
    padding: 700px !important;
  }
  .p-xxl-n15 {
    padding: -15px !important;
  }
  .p-xxl-n1 {
    padding: -10px !important;
  }
  .p-xxl-n5 {
    padding: -5px !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.625rem !important;
  }
  .p-xxl-2 {
    padding: 1.25rem !important;
  }
  .p-xxl-3 {
    padding: 1.875rem !important;
  }
  .p-xxl-4 {
    padding: 2.5rem !important;
  }
  .p-xxl-5 {
    padding: 3.125rem !important;
  }
  .p-xxl-6 {
    padding: 3.75rem !important;
  }
  .p-xxl-7 {
    padding: 4.375rem !important;
  }
  .p-xxl-8 {
    padding: 5rem !important;
  }
  .p-xxl-9 {
    padding: 5.625rem !important;
  }
  .p-xxl-10 {
    padding: 6.25rem !important;
  }
  .p-xxl-gutter {
    padding: 2.133rem !important;
  }
  .p-xxl-out-container {
    padding: calc(50% - 50vw) !important;
  }
  .px-xxl-sm {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }
  .px-xxl-3px {
    padding-right: 3px !important;
    padding-left: 3px !important;
  }
  .px-xxl-15px {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  .px-xxl-25px {
    padding-right: 25px !important;
    padding-left: 25px !important;
  }
  .px-xxl-35px {
    padding-right: 35px !important;
    padding-left: 35px !important;
  }
  .px-xxl-65px {
    padding-right: 65px !important;
    padding-left: 65px !important;
  }
  .px-xxl-74px {
    padding-right: 74px !important;
    padding-left: 74px !important;
  }
  .px-xxl-700 {
    padding-right: 700px !important;
    padding-left: 700px !important;
  }
  .px-xxl-n15 {
    padding-right: -15px !important;
    padding-left: -15px !important;
  }
  .px-xxl-n1 {
    padding-right: -10px !important;
    padding-left: -10px !important;
  }
  .px-xxl-n5 {
    padding-right: -5px !important;
    padding-left: -5px !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-xxl-2 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-xxl-3 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-xxl-4 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .px-xxl-6 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }
  .px-xxl-7 {
    padding-right: 4.375rem !important;
    padding-left: 4.375rem !important;
  }
  .px-xxl-8 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-xxl-9 {
    padding-right: 5.625rem !important;
    padding-left: 5.625rem !important;
  }
  .px-xxl-10 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important;
  }
  .px-xxl-gutter {
    padding-right: 2.133rem !important;
    padding-left: 2.133rem !important;
  }
  .px-xxl-out-container {
    padding-right: calc(50% - 50vw) !important;
    padding-left: calc(50% - 50vw) !important;
  }
  .py-xxl-sm {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  .py-xxl-3px {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
  }
  .py-xxl-15px {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .py-xxl-25px {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .py-xxl-35px {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .py-xxl-65px {
    padding-top: 65px !important;
    padding-bottom: 65px !important;
  }
  .py-xxl-74px {
    padding-top: 74px !important;
    padding-bottom: 74px !important;
  }
  .py-xxl-700 {
    padding-top: 700px !important;
    padding-bottom: 700px !important;
  }
  .py-xxl-n15 {
    padding-top: -15px !important;
    padding-bottom: -15px !important;
  }
  .py-xxl-n1 {
    padding-top: -10px !important;
    padding-bottom: -10px !important;
  }
  .py-xxl-n5 {
    padding-top: -5px !important;
    padding-bottom: -5px !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-xxl-2 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-xxl-3 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-xxl-4 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .py-xxl-6 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .py-xxl-7 {
    padding-top: 4.375rem !important;
    padding-bottom: 4.375rem !important;
  }
  .py-xxl-8 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-xxl-9 {
    padding-top: 5.625rem !important;
    padding-bottom: 5.625rem !important;
  }
  .py-xxl-10 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }
  .py-xxl-gutter {
    padding-top: 2.133rem !important;
    padding-bottom: 2.133rem !important;
  }
  .py-xxl-out-container {
    padding-top: calc(50% - 50vw) !important;
    padding-bottom: calc(50% - 50vw) !important;
  }
  .pt-xxl-sm {
    padding-top: 0.3125rem !important;
  }
  .pt-xxl-3px {
    padding-top: 3px !important;
  }
  .pt-xxl-15px {
    padding-top: 15px !important;
  }
  .pt-xxl-25px {
    padding-top: 25px !important;
  }
  .pt-xxl-35px {
    padding-top: 35px !important;
  }
  .pt-xxl-65px {
    padding-top: 65px !important;
  }
  .pt-xxl-74px {
    padding-top: 74px !important;
  }
  .pt-xxl-700 {
    padding-top: 700px !important;
  }
  .pt-xxl-n15 {
    padding-top: -15px !important;
  }
  .pt-xxl-n1 {
    padding-top: -10px !important;
  }
  .pt-xxl-n5 {
    padding-top: -5px !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.625rem !important;
  }
  .pt-xxl-2 {
    padding-top: 1.25rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1.875rem !important;
  }
  .pt-xxl-4 {
    padding-top: 2.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3.125rem !important;
  }
  .pt-xxl-6 {
    padding-top: 3.75rem !important;
  }
  .pt-xxl-7 {
    padding-top: 4.375rem !important;
  }
  .pt-xxl-8 {
    padding-top: 5rem !important;
  }
  .pt-xxl-9 {
    padding-top: 5.625rem !important;
  }
  .pt-xxl-10 {
    padding-top: 6.25rem !important;
  }
  .pt-xxl-gutter {
    padding-top: 2.133rem !important;
  }
  .pt-xxl-out-container {
    padding-top: calc(50% - 50vw) !important;
  }
  .pe-xxl-sm {
    padding-right: 0.3125rem !important;
  }
  .pe-xxl-3px {
    padding-right: 3px !important;
  }
  .pe-xxl-15px {
    padding-right: 15px !important;
  }
  .pe-xxl-25px {
    padding-right: 25px !important;
  }
  .pe-xxl-35px {
    padding-right: 35px !important;
  }
  .pe-xxl-65px {
    padding-right: 65px !important;
  }
  .pe-xxl-74px {
    padding-right: 74px !important;
  }
  .pe-xxl-700 {
    padding-right: 700px !important;
  }
  .pe-xxl-n15 {
    padding-right: -15px !important;
  }
  .pe-xxl-n1 {
    padding-right: -10px !important;
  }
  .pe-xxl-n5 {
    padding-right: -5px !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.625rem !important;
  }
  .pe-xxl-2 {
    padding-right: 1.25rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1.875rem !important;
  }
  .pe-xxl-4 {
    padding-right: 2.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3.125rem !important;
  }
  .pe-xxl-6 {
    padding-right: 3.75rem !important;
  }
  .pe-xxl-7 {
    padding-right: 4.375rem !important;
  }
  .pe-xxl-8 {
    padding-right: 5rem !important;
  }
  .pe-xxl-9 {
    padding-right: 5.625rem !important;
  }
  .pe-xxl-10 {
    padding-right: 6.25rem !important;
  }
  .pe-xxl-gutter {
    padding-right: 2.133rem !important;
  }
  .pe-xxl-out-container {
    padding-right: calc(50% - 50vw) !important;
  }
  .pb-xxl-sm {
    padding-bottom: 0.3125rem !important;
  }
  .pb-xxl-3px {
    padding-bottom: 3px !important;
  }
  .pb-xxl-15px {
    padding-bottom: 15px !important;
  }
  .pb-xxl-25px {
    padding-bottom: 25px !important;
  }
  .pb-xxl-35px {
    padding-bottom: 35px !important;
  }
  .pb-xxl-65px {
    padding-bottom: 65px !important;
  }
  .pb-xxl-74px {
    padding-bottom: 74px !important;
  }
  .pb-xxl-700 {
    padding-bottom: 700px !important;
  }
  .pb-xxl-n15 {
    padding-bottom: -15px !important;
  }
  .pb-xxl-n1 {
    padding-bottom: -10px !important;
  }
  .pb-xxl-n5 {
    padding-bottom: -5px !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.625rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 1.25rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1.875rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3.125rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 3.75rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 4.375rem !important;
  }
  .pb-xxl-8 {
    padding-bottom: 5rem !important;
  }
  .pb-xxl-9 {
    padding-bottom: 5.625rem !important;
  }
  .pb-xxl-10 {
    padding-bottom: 6.25rem !important;
  }
  .pb-xxl-gutter {
    padding-bottom: 2.133rem !important;
  }
  .pb-xxl-out-container {
    padding-bottom: calc(50% - 50vw) !important;
  }
  .ps-xxl-sm {
    padding-left: 0.3125rem !important;
  }
  .ps-xxl-3px {
    padding-left: 3px !important;
  }
  .ps-xxl-15px {
    padding-left: 15px !important;
  }
  .ps-xxl-25px {
    padding-left: 25px !important;
  }
  .ps-xxl-35px {
    padding-left: 35px !important;
  }
  .ps-xxl-65px {
    padding-left: 65px !important;
  }
  .ps-xxl-74px {
    padding-left: 74px !important;
  }
  .ps-xxl-700 {
    padding-left: 700px !important;
  }
  .ps-xxl-n15 {
    padding-left: -15px !important;
  }
  .ps-xxl-n1 {
    padding-left: -10px !important;
  }
  .ps-xxl-n5 {
    padding-left: -5px !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.625rem !important;
  }
  .ps-xxl-2 {
    padding-left: 1.25rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1.875rem !important;
  }
  .ps-xxl-4 {
    padding-left: 2.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3.125rem !important;
  }
  .ps-xxl-6 {
    padding-left: 3.75rem !important;
  }
  .ps-xxl-7 {
    padding-left: 4.375rem !important;
  }
  .ps-xxl-8 {
    padding-left: 5rem !important;
  }
  .ps-xxl-9 {
    padding-left: 5.625rem !important;
  }
  .ps-xxl-10 {
    padding-left: 6.25rem !important;
  }
  .ps-xxl-gutter {
    padding-left: 2.133rem !important;
  }
  .ps-xxl-out-container {
    padding-left: calc(50% - 50vw) !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xxl-sm {
    gap: 0.3125rem !important;
  }
  .gap-xxl-3px {
    gap: 3px !important;
  }
  .gap-xxl-15px {
    gap: 15px !important;
  }
  .gap-xxl-25px {
    gap: 25px !important;
  }
  .gap-xxl-35px {
    gap: 35px !important;
  }
  .gap-xxl-65px {
    gap: 65px !important;
  }
  .gap-xxl-74px {
    gap: 74px !important;
  }
  .gap-xxl-700 {
    gap: 700px !important;
  }
  .gap-xxl-n15 {
    gap: -15px !important;
  }
  .gap-xxl-n1 {
    gap: -10px !important;
  }
  .gap-xxl-n5 {
    gap: -5px !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.625rem !important;
  }
  .gap-xxl-2 {
    gap: 1.25rem !important;
  }
  .gap-xxl-3 {
    gap: 1.875rem !important;
  }
  .gap-xxl-4 {
    gap: 2.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3.125rem !important;
  }
  .gap-xxl-6 {
    gap: 3.75rem !important;
  }
  .gap-xxl-7 {
    gap: 4.375rem !important;
  }
  .gap-xxl-8 {
    gap: 5rem !important;
  }
  .gap-xxl-9 {
    gap: 5.625rem !important;
  }
  .gap-xxl-10 {
    gap: 6.25rem !important;
  }
  .gap-xxl-gutter {
    gap: 2.133rem !important;
  }
  .gap-xxl-out-container {
    gap: calc(50% - 50vw) !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .vh-xxl-30 {
    height: 30vh !important;
  }
  .vh-xxl-40 {
    height: 40vh !important;
  }
  .vh-xxl-50 {
    height: 50vh !important;
  }
  .vh-xxl-60 {
    height: 60vh !important;
  }
  .vh-xxl-65 {
    height: 65vh !important;
  }
  .vh-xxl-70 {
    height: 70vh !important;
  }
  .vh-xxl-80 {
    height: 80vh !important;
  }
  .vh-xxl-100 {
    height: 100vh !important;
  }
  .w-xxl-10 {
    width: 10% !important;
  }
  .w-xxl-20 {
    width: 20% !important;
  }
  .w-xxl-30 {
    width: 30% !important;
  }
  .w-xxl-40 {
    width: 40% !important;
  }
  .w-xxl-50 {
    width: 50% !important;
  }
  .w-xxl-60 {
    width: 60% !important;
  }
  .w-xxl-70 {
    width: 70% !important;
  }
  .w-xxl-75 {
    width: 75% !important;
  }
  .w-xxl-80 {
    width: 80% !important;
  }
  .w-xxl-85 {
    width: 85% !important;
  }
  .w-xxl-90 {
    width: 90% !important;
  }
  .w-xxl-100 {
    width: 100% !important;
  }
  .w-xxl-auto {
    width: auto !important;
  }
  .w-xxl-20px {
    width: 20px !important;
  }
  .w-xxl-80px {
    width: 80px !important;
  }
  .w-xxl-180 {
    width: 180px !important;
  }
  .w-xxl-200px {
    width: 200px !important;
  }
  .w-xxl-cal200 {
    width: calc(100% - 200px) !important;
  }
  .h-xxl-25 {
    height: 25% !important;
  }
  .h-xxl-50 {
    height: 50% !important;
  }
  .h-xxl-75 {
    height: 75% !important;
  }
  .h-xxl-100 {
    height: 100% !important;
  }
  .h-xxl-20px {
    height: 20px !important;
  }
  .h-xxl-27px {
    height: 27px !important;
  }
  .h-xxl-auto {
    height: auto !important;
  }
  .h-xxl-100vh {
    height: 100vh !important;
  }
  .h-xxl-300px {
    height: 300px !important;
  }
  .h-xxl-400px {
    height: 400px !important;
  }
  .h-xxl-600px {
    height: 600px !important;
  }
  .h-xxl-750px {
    height: 750px !important;
  }
  .fs-xxl-1 {
    font-size: calc(1.375rem + 1.5vw) !important;
  }
  .fs-xxl-2 {
    font-size: calc(1.34375rem + 1.125vw) !important;
  }
  .fs-xxl-3 {
    font-size: calc(1.2875rem + 0.45vw) !important;
  }
  .fs-xxl-4 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }
  .fs-xxl-5 {
    font-size: calc(1.2625rem + 0.15vw) !important;
  }
  .fs-xxl-6 {
    font-size: 1.125rem !important;
  }
  .fs-xxl-7 {
    font-size: 1rem !important;
  }
  .fs-xxl-xl {
    font-size: calc(1.5rem + 3vw) !important;
  }
  .fs-xxl-lg {
    font-size: calc(1.3125rem + 0.75vw) !important;
  }
  .fs-xxl-md {
    font-size: calc(1.35rem + 1.2vw) !important;
  }
  .fs-xxl-sm {
    font-size: 0.875rem !important;
  }
  .fs-xxl-xs {
    font-size: 0.75rem !important;
  }
  .fs-xxl-xxs {
    font-size: 0.625rem !important;
  }
  .fs-xxl-15 {
    font-size: 0.9375rem !important;
  }
  .fs-xxl-12 {
    font-size: 0.75rem !important;
  }
  .fs-xxl-25 {
    font-size: calc(1.28125rem + 0.375vw) !important;
  }
  .fs-xxl-30 {
    font-size: calc(1.3125rem + 0.75vw) !important;
  }
  .fs-xxl-55 {
    font-size: calc(1.46875rem + 2.625vw) !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .order-xxl-unset {
    order: unset !important;
  }
  .cursor-xxl-auto {
    cursor: auto !important;
  }
  .cursor-xxl-pointer {
    cursor: pointer !important;
  }
  .cursor-xxl-grab {
    cursor: grab !important;
  }
  .border-xxl {
    border: 1px solid #5a5a5a !important;
  }
  .border-xxl-0 {
    border: 0 !important;
  }
  .border-top-xxl {
    border-top: 1px solid #5a5a5a !important;
  }
  .border-top-xxl-0 {
    border-top: 0 !important;
  }
  .border-start-xxl {
    border-left: 1px solid #5a5a5a !important;
  }
  .border-start-xxl-0 {
    border-left: 0 !important;
  }
  .border-end-xxl {
    border-right: 1px solid #5a5a5a !important;
  }
  .border-end-xxl-0 {
    border-right: 0 !important;
  }
  .border-bottom-xxl {
    border-bottom: 1px solid #5a5a5a !important;
  }
  .border-bottom-xxl-0 {
    border-bottom: 0 !important;
  }
  .shadow-xxl {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16) !important;
  }
  .shadow-xxl-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-xxl-lg {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16) !important;
  }
  .shadow-xxl-none {
    box-shadow: none !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
  .text-decoration-xxl-none {
    text-decoration: none !important;
  }
  .text-decoration-xxl-underline {
    text-decoration: underline !important;
  }
  .text-decoration-xxl-line-through {
    text-decoration: line-through !important;
  }
  .text-xxl-lowercase {
    text-transform: lowercase !important;
  }
  .text-xxl-uppercase {
    text-transform: uppercase !important;
  }
  .text-xxl-capitalize {
    text-transform: capitalize !important;
  }
  .position-xxl-static {
    position: static !important;
  }
  .position-xxl-relative {
    position: relative !important;
  }
  .position-xxl-absolute {
    position: absolute !important;
  }
  .position-xxl-fixed {
    position: fixed !important;
  }
  .position-xxl-sticky {
    position: sticky !important;
  }
  .top-xxl-0 {
    top: 0 !important;
  }
  .top-xxl-25 {
    top: 25% !important;
  }
  .top-xxl-50 {
    top: 50% !important;
  }
  .top-xxl-75 {
    top: 75% !important;
  }
  .top-xxl-100 {
    top: 100% !important;
  }
  .top-xxl-10 {
    top: 10px !important;
  }
  .top-xxl-20 {
    top: 20px !important;
  }
  .top-xxl-30 {
    top: 30px !important;
  }
  .top-xxl-n10 {
    top: -10px !important;
  }
  .top-xxl-n20 {
    top: -20px !important;
  }
  .top-xxl-n8 {
    top: -8px !important;
  }
  .top-xxl-7 {
    top: 7px !important;
  }
  .top-xxl-13 {
    top: 13px !important;
  }
  .top-xxl-15 {
    top: 15px !important;
  }
  .top-xxl-n3 {
    top: -3px !important;
  }
  .top-xxl-5 {
    top: 5px !important;
  }
  .top-xxl-2 {
    top: 2px !important;
  }
  .bottom-xxl-0 {
    bottom: 0 !important;
  }
  .bottom-xxl-25 {
    bottom: 25% !important;
  }
  .bottom-xxl-50 {
    bottom: 50% !important;
  }
  .bottom-xxl-75 {
    bottom: 75% !important;
  }
  .bottom-xxl-100 {
    bottom: 100% !important;
  }
  .bottom-xxl-10 {
    bottom: 10px !important;
  }
  .bottom-xxl-20 {
    bottom: 20px !important;
  }
  .bottom-xxl-30 {
    bottom: 30px !important;
  }
  .bottom-xxl-n10 {
    bottom: -10px !important;
  }
  .bottom-xxl-n20 {
    bottom: -20px !important;
  }
  .bottom-xxl-n8 {
    bottom: -8px !important;
  }
  .bottom-xxl-7 {
    bottom: 7px !important;
  }
  .bottom-xxl-13 {
    bottom: 13px !important;
  }
  .bottom-xxl-15 {
    bottom: 15px !important;
  }
  .bottom-xxl-n3 {
    bottom: -3px !important;
  }
  .bottom-xxl-5 {
    bottom: 5px !important;
  }
  .bottom-xxl-2 {
    bottom: 2px !important;
  }
  .end-xxl-0 {
    right: 0 !important;
  }
  .end-xxl-25 {
    right: 25% !important;
  }
  .end-xxl-50 {
    right: 50% !important;
  }
  .end-xxl-75 {
    right: 75% !important;
  }
  .end-xxl-100 {
    right: 100% !important;
  }
  .end-xxl-10 {
    right: 10px !important;
  }
  .end-xxl-20 {
    right: 20px !important;
  }
  .end-xxl-30 {
    right: 30px !important;
  }
  .end-xxl-n10 {
    right: -10px !important;
  }
  .end-xxl-n20 {
    right: -20px !important;
  }
  .end-xxl-n8 {
    right: -8px !important;
  }
  .end-xxl-7 {
    right: 7px !important;
  }
  .end-xxl-13 {
    right: 13px !important;
  }
  .end-xxl-15 {
    right: 15px !important;
  }
  .end-xxl-n3 {
    right: -3px !important;
  }
  .end-xxl-5 {
    right: 5px !important;
  }
  .end-xxl-2 {
    right: 2px !important;
  }
  .translate-middle-xxl {
    transform: translate(-50%, -50%) !important;
  }
  .translate-middle-xxl-x {
    transform: translateX(-50%) !important;
  }
  .translate-middle-xxl-y {
    transform: translateY(-50%) !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2.1875rem !important;
  }
  .fs-3 {
    font-size: 1.625rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
  .fs-5 {
    font-size: 1.375rem !important;
  }
  .fs-xl {
    font-size: 3.75rem !important;
  }
  .fs-lg {
    font-size: 1.875rem !important;
  }
  .fs-md {
    font-size: 2.25rem !important;
  }
  .fs-25 {
    font-size: 1.5625rem !important;
  }
  .fs-30 {
    font-size: 1.875rem !important;
  }
  .fs-55 {
    font-size: 3.4375rem !important;
  }
  .fs-sm-1 {
    font-size: 2.5rem !important;
  }
  .fs-sm-2 {
    font-size: 2.1875rem !important;
  }
  .fs-sm-3 {
    font-size: 1.625rem !important;
  }
  .fs-sm-4 {
    font-size: 1.5rem !important;
  }
  .fs-sm-5 {
    font-size: 1.375rem !important;
  }
  .fs-sm-xl {
    font-size: 3.75rem !important;
  }
  .fs-sm-lg {
    font-size: 1.875rem !important;
  }
  .fs-sm-md {
    font-size: 2.25rem !important;
  }
  .fs-sm-25 {
    font-size: 1.5625rem !important;
  }
  .fs-sm-30 {
    font-size: 1.875rem !important;
  }
  .fs-sm-55 {
    font-size: 3.4375rem !important;
  }
  .fs-md-1 {
    font-size: 2.5rem !important;
  }
  .fs-md-2 {
    font-size: 2.1875rem !important;
  }
  .fs-md-3 {
    font-size: 1.625rem !important;
  }
  .fs-md-4 {
    font-size: 1.5rem !important;
  }
  .fs-md-5 {
    font-size: 1.375rem !important;
  }
  .fs-md-xl {
    font-size: 3.75rem !important;
  }
  .fs-md-lg {
    font-size: 1.875rem !important;
  }
  .fs-md-md {
    font-size: 2.25rem !important;
  }
  .fs-md-25 {
    font-size: 1.5625rem !important;
  }
  .fs-md-30 {
    font-size: 1.875rem !important;
  }
  .fs-md-55 {
    font-size: 3.4375rem !important;
  }
  .fs-lg-1 {
    font-size: 2.5rem !important;
  }
  .fs-lg-2 {
    font-size: 2.1875rem !important;
  }
  .fs-lg-3 {
    font-size: 1.625rem !important;
  }
  .fs-lg-4 {
    font-size: 1.5rem !important;
  }
  .fs-lg-5 {
    font-size: 1.375rem !important;
  }
  .fs-lg-xl {
    font-size: 3.75rem !important;
  }
  .fs-lg-lg {
    font-size: 1.875rem !important;
  }
  .fs-lg-md {
    font-size: 2.25rem !important;
  }
  .fs-lg-25 {
    font-size: 1.5625rem !important;
  }
  .fs-lg-30 {
    font-size: 1.875rem !important;
  }
  .fs-lg-55 {
    font-size: 3.4375rem !important;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-llg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-llg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-llg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-llg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-llg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-llg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-llg .navbar-toggler {
    display: none;
  }
  .navbar-expand-llg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-llg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-llg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .btn-lg {
    --bs-btn-font-size: 1.875rem;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}

@media (min-width: 1200px) {
  .col-form-label-lg {
    font-size: 1.875rem;
  }
}

@media (min-width: 1200px) {
  .form-control-lg {
    font-size: 1.875rem;
  }
}

@media (min-width: 1200px) {
  .form-select-lg {
    font-size: 1.875rem;
  }
}

@media (min-width: 1200px) {
  .input-group-lg > .form-control,
  .input-group-lg > .form-select,
  .input-group-lg > .input-group-text,
  .input-group-lg > .btn {
    font-size: 1.875rem;
  }
}


@media (min-width: 992px) {
  @keyframes menuFixed {
    0% {
      top: -60px;
    }
    100% {
      top: 0;
    }
  }
  @keyframes exampleMove {
    from {
      opacity: 0;
      top: 30px;
    }
    to {
      opacity: 1;
      top: 46px;
    }
  }
  @keyframes exampleMove01 {
    from {
      opacity: 0;
      left: 310px;
    }
    to {
      opacity: 1;
      left: 315px;
    }
  }
  .hotline {
    padding: 2px 0 3px;
  }
  .hotline p {
    margin: 0;
  }
  .search_toggle_block {
    padding-left: 100px;
    padding-right: 100px;
  }
  #header.fixed {
    animation: menuFixed 0.5s linear forwards;
  }
  #header.fixed .header_logo img {
    width: 120px;
  }
  .header {
    border-bottom: none;
    padding: 0;
    background: transparent;
  }
  .header_logo img {
    width: 145px;
    transition: 0.3s;
  }
  .header .main_menu {
    position: static;
    height: inherit !important;
    padding: 0;
    max-width: 100%;
    background: transparent;
    margin-top: 0 !important;
    transition: inherit;
  }
  .header .main_menu > .menu {
    height: unset;
    overflow: unset;
    padding: 0;
  }
  .header .main_menu > .menu > div > ul {
    border: none;
  }
  .header .main_menu > .menu > div > ul > li {
    border: none;
    padding: 0 11px;
  }
  .header .main_menu > .menu > div > ul > li.menu-item-8 > a::before {
    background-color: #22a2a2;
  }
  .header .main_menu > .menu > div > ul > li.menu-item-9 > a::before {
    background-color: #e0c7a7;
  }
  .header .main_menu > .menu > div > ul > li > a {
    margin-left: 10px;
    padding: 15px 0;
    position: relative;
    color: #ffffff;
  }
  .header .main_menu > .menu > div > ul > li > a:hover {
    color: #ffffff;
  }
  .header .main_menu > .menu > div > ul > li > a:hover::before {
    width: 100%;
  }
  .header .main_menu > .menu > div > ul > li.menu-item-has-children {
    position: relative;
    padding-right: 20px;
  }
  .header .main_menu > .menu > div > ul > li.menu-item-has-children:before {
    position: absolute;
    content: "";
    right: 0;
    top: 5px;
    width: 17px;
    height: 17px;
    background-size: cover;
  }
  .header .main_menu > .menu > div > ul > li.menu-item-has-children > a:after {
    position: absolute;
    content: "";
    left: 0;
    bottom: -23px;
    width: 100%;
    height: 30px;
    display: block;
    background: transparent;
  }
  .header .main_menu > .menu > div > ul > li.menu-item-has-children > ul {
    left: 0;
    padding: 0;
    text-transform: capitalize;
    width: 315px;
    position: absolute;
    display: none;
    list-style: none;
    z-index: 6;
  }
  .header .main_menu > .menu > div > ul > li.menu-item-has-children > ul > li a {
    color: #000000;
    padding: 10px 10px 10px 25px;
    text-transform: uppercase;
    position: relative;
  }
  .header .main_menu > .menu > div > ul > li.menu-item-has-children > ul > li a:hover {
    text-decoration: none;
    color: #df532d;
  }
  .header .main_menu > .menu > div > ul > li.menu-item-has-children > ul > li a:hover:before {
    height: 100%;
  }
  .header .main_menu > .menu > div > ul > li.menu-item-has-children:hover > ul {
    -webkit-animation-name: exampleMove;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    animation-name: exampleMove;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    display: block;
  }
  .header .main_menu > .menu > div > ul > li:last-child {
    padding-right: 10px;
  }
  .phone_project {
    align-items: center;
  }
  .phone_project .menu_phone {
    margin: 0 10px 0 20px;
    padding-bottom: 3px;
  }
  .phone_project .menu_phone:before {
    height: 1px;
  }
  .phone_project p {
    margin-bottom: 0 !important;
  }
  .phone_project .widget_block:last-child {
    margin-left: 15px;
  }
}